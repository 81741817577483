import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {Injectable, Injector, OnDestroy} from '@angular/core';
import {CryptoKX} from 'libsodium-wrappers-sumo';
import {from, Observable, of, Subject, throwError, timer} from "rxjs";
import {catchError, finalize, map, publishReplay, refCount, retryWhen, switchMap, takeUntil, tap} from "rxjs/operators";

import {buildUrl} from "../../utils/build-url";
import {memzero} from "../../utils/memzero";
import {ApiService} from "../api/api.service";
import {ErrorService} from "../error/error.service";
import {FileReaderService} from "../file-reader/file-reader.service";
import {SodiumService} from "../sodium/sodium.service";

const apiRegexp = new RegExp(`^${buildUrl()}`);
const sessionRegexp = new RegExp(`^${buildUrl('session')}\$`);

@Injectable()
export class EncryptionInterceptor implements HttpInterceptor, OnDestroy {
  private session: { key: CryptoKX, info: string };
  private refreshing: Observable<{ key: CryptoKX, info: string }>;

  private readonly destroy = new Subject<undefined>();

  constructor(private readonly injector: Injector,
              private readonly fileReader: FileReaderService,
              private readonly sodium: SodiumService) {
    timer(0, 600000)
      .pipe(
        switchMap(() => this.refresh()),
        catchError(() => of<undefined>(undefined)),
        takeUntil(this.destroy)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  /**
   * Intercept the request and perform the encryption routines on it
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Exclude the session request since this will not be encrypted
    if (sessionRegexp.test(req.url) || !apiRegexp.test(req.url)) {
      return next.handle(req);
    }

    return this.sessionKey()
      .pipe(
        switchMap(
          ({key, info}) => this.beforeRequest(req.clone({
            withCredentials: true,
            headers: req.headers.set('X-Session-Info', info),
          }), key.sharedTx)
            .pipe(
              switchMap(encryptedRequest => next.handle(encryptedRequest)),
              switchMap(
                event => event instanceof HttpResponse ?
                  this.response(event, req.responseType, key.sharedRx) :
                  of<HttpEvent<any>>(event)
              ),
              catchError(
                error => error instanceof HttpErrorResponse ?
                  this.errorResponse(error, req.responseType, key.sharedRx) :
                  throwError(error)
              )
            )
        ),
        retryWhen(errors => errors.pipe(
          switchMap(error => this.refresh(error))
        ))
      );
  }

  /**
   * Encrypt the request using symmetric key encryption (if needed)
   */
  private beforeRequest(req: HttpRequest<any>, key: Uint8Array): Observable<HttpRequest<any>> {
    const request = req.clone({
      headers: req.headers.set('X-Accept-Encrypted', 'application/octet-stream'),
      responseType: 'arraybuffer'
    });

    if (['POST', 'PUT', 'PATCH'].indexOf(request.method) === -1) {
      return of(request);
    }

    const body = request.serializeBody();
    if (!body) {
      return of(request);
    }

    if (body instanceof FormData) {
      return throwError(new Error('Encryption cannot be used with FormData, use another way to transfer the data'));
    }

    return of(null)
      .pipe(
        switchMap(() => this.encrypt(body, request.headers, key)),
        map(({body: encryptedBody, headers}) => request.clone({body: encryptedBody, headers}))
      );
  }

  /**
   * Perform the encryption
   */
  private encrypt(body: Blob | ArrayBuffer | string,
                  headers: HttpHeaders,
                  key: Uint8Array): Observable<{ body: ArrayBuffer, headers: HttpHeaders }> {
    const _body = body instanceof Blob
      ? this.fileReader.readAsArrayBuffer(body)
        .pipe(map(data => new Uint8Array(data)))
      : (
        typeof body === 'string'
          ? from(this.sodium.fromString(body))
          : of(new Uint8Array(body))
      );

    const contentType = (body instanceof Blob ?
      body.type || 'application/octet-stream' :
      headers.get('Content-Type')) || 'application/json';

    return _body.pipe(
      switchMap(data => {
        const contentLength = data.byteLength;
        const additionalData = `${contentType}${contentLength}`;

        return from(this.sodium.aeadEncrypt(data, key, additionalData))
          .pipe(
            map(result => ({
              body: result.buffer,
              headers: headers
                .set('Content-Type', 'application/octet-stream')
                .set('X-Original-Content-Type', contentType)
                .set('X-Original-Content-Length', `${contentLength}`)
            }))
          )
      }),
    );
  }

  /**
   * Decrypt the response using symmetric key encryption (if needed)
   */
  private response(response: HttpResponse<any>,
                   responseType: 'arraybuffer' | 'blob' | 'json' | 'text',
                   key: Uint8Array): Observable<HttpResponse<any>> {
    const body = response.body as ArrayBuffer;
    if (!body) {
      return of<HttpResponse<any>>(response);
    }

    if (!response.headers.has('X-Original-Content-Type')) {
      return of(null)
        .pipe(
          switchMap(() => this.convertBody(body, responseType, response.headers.get('Content-Type'))),
          map(result => response.clone({body: result}))
        );
    }

    return of(null)
      .pipe(
        switchMap(() => this.decrypt(body, response.headers, responseType, key)),
        map(({body: decryptedBody, headers}) => response.clone({body: decryptedBody, headers}))
      );
  }

  /**
   * Decrypt the error response using symmetric key encryption (if needed)
   */
  private errorResponse(response: HttpErrorResponse,
                        responseType: "arraybuffer" | "blob" | "json" | "text",
                        key: Uint8Array): Observable<HttpResponse<any>> {
    const body = response.error;
    if (!body || !(body instanceof ArrayBuffer) || response.status === 0 || response.status === -1) {
      return throwError(response);
    }

    if (!response.headers.has('X-Original-Content-Type')) {
      return of(null)
        .pipe(
          switchMap(() => this.convertBody(body, responseType, response.headers.get('Content-Type'))),
          switchMap(result => throwError(
            new HttpErrorResponse({
              error: result,
              headers: response.headers,
              status: response.status,
              statusText: response.statusText,
              url: response.url
            })
          ))
        );
    }

    return of(null)
      .pipe(
        switchMap(() => this.decrypt(body, response.headers, responseType, key)),
        switchMap(({body: decryptedBody, headers}) => throwError(
          new HttpErrorResponse({
            error: decryptedBody,
            headers,
            status: response.status,
            statusText: response.statusText,
            url: response.url
          })
        ))
      );
  }

  /**
   * Perform the decryption
   */
  private decrypt(body: Blob | ArrayBuffer | string,
                  headers: HttpHeaders,
                  responseType: 'arraybuffer' | 'blob' | 'json' | 'text',
                  key: Uint8Array): Observable<{ body: ArrayBuffer | Blob | string | any, headers: HttpHeaders }> {
    const _body = body instanceof Blob
      ? this.fileReader.readAsArrayBuffer(body)
        .pipe(map(data => new Uint8Array(data)))
      : (
        typeof body === 'string'
          ? from(this.sodium.fromString(body))
          : of(new Uint8Array(body))
      );

    const contentType = headers.get('X-Original-Content-Type');
    const contentLength = headers.get('X-Original-Content-Length');
    const additionalData = `${contentType}${contentLength}`;

    return _body.pipe(
      switchMap(
        data => from(this.sodium.aeadDecrypt(data, key, additionalData))
          .pipe(
            switchMap(result => this.convertBody(result.buffer, responseType, contentType)),
            map(result => ({
              body: result,
              headers: headers
                .set('Content-Type', contentType)
                .set('Content-Length', contentLength)
                .delete('X-Original-Content-Type')
                .delete('X-Original-Content-Length')
            }))
          )
      )
    );
  }

  /**
   * Convert the (decrypted) body based on the original requested responseType
   */
  private async convertBody(body: ArrayBuffer,
                            responseType: 'arraybuffer' | 'blob' | 'json' | 'text',
                            contentType = 'application/json'): Promise<ArrayBuffer | Blob | string | any> {
    if (responseType === 'arraybuffer') {
      return body;
    }

    if (responseType === 'blob') {
      return new Blob([body], {type: contentType});
    }

    if (responseType === 'json' && contentType === 'application/json') {
      return JSON.parse(await this.sodium.toString(new Uint8Array(body)));
    }

    return this.sodium.toString(new Uint8Array(body));
  }

  private sessionKey(): Observable<{ key: CryptoKX, info: string }> {
    if (this.refreshing) {
      return this.refreshing;
    }

    if (this.session) {
      return of(this.session);
    }

    return this.refresh();
  }

  private refresh(error?: any): Observable<{ key: CryptoKX, info: string }> {
    if (error && (!(error instanceof HttpErrorResponse) || error.status !== 400 || error.headers.get('X-Session-Error') !== '1')) {
      return throwError(error);
    }

    if (this.refreshing) {
      return this.refreshing;
    }

    const apiService = this.injector.get(ApiService);

    this.refreshing = from(this.sodium.generateKeyPair())
      .pipe(
        switchMap(
          keyPair => from(this.sodium.toHex(keyPair.publicKey))
            .pipe(
              switchMap(publicKey => apiService.sessionKey(publicKey)),
              retryWhen(errors => errors.pipe(
                switchMap((refreshError, count) => {
                  console.warn(refreshError);

                  if (count === 5) {
                    const errorService = this.injector.get(ErrorService);

                    errorService.networkError();
                  }

                  return timer(count * 100);
                })
              )),
              switchMap(
                ({public_key: serverKey, session_info: info}) => from(this.sodium.sessionKeys(keyPair, serverKey))
                  .pipe(map(key => this.session = {key, info}))
              ),
              tap(() => {
                memzero(keyPair.publicKey);
                memzero(keyPair.privateKey);
              })
            )
        ),
        finalize(() => this.refreshing = null),
        takeUntil(this.destroy),
        publishReplay(1),
        refCount()
      );

    return this.refreshing.pipe(catchError(refreshError => throwError(error || refreshError)));
  }
}
