import {HttpErrorResponse} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {MatDialog, MatSnackBar} from "@angular/material";
import {Router} from "@angular/router";
import {marker as translatable} from "@biesbjerg/ngx-translate-extract-marker";
import {TranslateService} from "@ngx-translate/core";
import {switchMap} from "rxjs/operators";

import {OriginalNavigation} from "../../auth/auth.types";
import {AlertDialogComponent, AlertDialogData} from "../../base/alert-dialog/alert-dialog.component";

const FORM_FIELDS = [
  'editor',
  'evicare-custom-select',
  'evicare-slider',
  'input',
  'textarea',
  'mat-checkbox',
  'mat-radio-group',
  'mat-select',
  'mat-slide-toggle',
];

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(private readonly dialog: MatDialog,
              private readonly snackBar: MatSnackBar,
              private readonly router: Router,
              private readonly translate: TranslateService) {
  }

  handle(error: any): void {
    console.warn(error);

    if (error instanceof Error) {
      if (error.message === 'otp_required') {
        this.otpError();

        return;
      }

      if (error.message === 'invalid_password') {
        this.passwordError();

        return;
      }
    }

    if (error instanceof HttpErrorResponse) {
      if (error.status < 100) {
        this.networkError();

        return;
      }

      if (error.status === 401) {
        this.authenticationError();

        return;
      }

      if (error.status === 402) {
        this.subscriptionError();

        return;
      }

      if (error.status === 403) {
        this.forbiddenError();

        return;
      }

      if (error.status === 404) {
        this.notFoundError();

        return;
      }

      if (error.status === 422) {
        this.validationError(error);

        return;
      }
    }

    this.translate.get('Errors.Unknown')
      .subscribe(message => this.snackBar.open(message, null, {
        duration: 3000,
      }));
  }

  handleReaderError(error: any): void {
    console.warn(error);

    this.translate.get('Errors.Reader.Unknown')
      .subscribe(message => this.snackBar.open(message, null, {
        duration: 3000,
      }));
  }

  networkError(): void {
    this.translate.get('Errors.Network')
      .subscribe(message => this.snackBar.open(message, null, {
        duration: 3000,
      }));
  }

  authenticationError(): void {
    this.translate.get('Errors.Unauthenticated')
      .subscribe(message => this.snackBar.open(message, null, {
        duration: 3000,
      }));

    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const intended: OriginalNavigation = route ? {
      route: this.router.routerState.snapshot.url,
      params: route.snapshot.queryParams,
      fragment: route.snapshot.fragment,
    } : undefined;

    this.router.navigate(['/auth'], {state: {intended}})
      .catch(reason => {
        console.warn(reason);
      });
  }

  forbiddenError(): void {
    this.translate.get('Errors.Forbidden')
      .subscribe(message => this.snackBar.open(message, null, {
        duration: 3000,
      }));
  }

  notFoundError(): void {
    this.translate.get('Errors.NotFound')
      .subscribe(message => this.snackBar.open(message, null, {
        duration: 3000,
      }));
  }

  otpError(): void {
    this.translate.get('Errors.OtpRequired')
      .subscribe(message => this.snackBar.open(message, null, {
        duration: 3000,
      }));
  }

  passwordError(): void {
    this.translate.get('Errors.InvalidCredentials')
      .subscribe(message => this.snackBar.open(message, null, {
        duration: 3000,
      }));
  }

  subscriptionError(): void {
    this.translate.get('Errors.PaymentRequired')
      .subscribe(message => this.snackBar.open(message, null, {
        duration: 3000,
      }));
  }

  validationError(error?: any): void {
    if (error) {
      if (typeof error === 'string') {
        this.translate.get('Errors.Validation', {cause: error})
          .subscribe(message => this.snackBar.open(message, null, {
            duration: 3000,
          }));

        return;
      }

      if (error instanceof HttpErrorResponse) {
        if (typeof error.error === 'string') {
          this.translate.get('Errors.Validation', {cause: error.error})
            .subscribe(message => this.snackBar.open(message, null, {
              duration: 3000,
            }));

          return;
        }

        if (typeof error.error === 'object') {
          if ('errors' in error.error) {
            const keys = Object.keys(error.error.errors);

            if (keys.length) {
              const value = keys
                .map(key => error.error.errors[key])
                .map(_value => Array.isArray(_value) ? _value[0] : _value)
                .filter(_value => _value && typeof _value === 'string')
                .shift();

              if (value) {
                this.translate.get('Errors.Validation', {cause: value})
                  .subscribe(message => this.snackBar.open(message, null, {
                    duration: 3000,
                  }));

                return;
              }
            }
          }

          if ('message' in error.error) {
            this.translate.get('Errors.Validation', {cause: error.error.message})
              .subscribe(message => this.snackBar.open(message, null, {
                duration: 3000,
              }));

            return;
          }
        }

        this.translate.get('Errors.ServerValidation')
          .subscribe(message => this.snackBar.open(message, null, {
            duration: 3000,
          }));

        return;
      }

      if (error instanceof Error && error.message) {
        this.translate.get(error.message)
          .pipe(
            switchMap(message => this.translate.get('Errors.Validation', {cause: message}))
          )
          .subscribe(message => this.snackBar.open(message, null, {
            duration: 3000,
          }));

        return;
      }
    }

    this.translate.get('Errors.GenericValidation')
      .subscribe(message => this.snackBar.open(message, null, {
        duration: 3000,
      }));
  }

  formInvalid(): void {
    const ref = this.dialog.open<AlertDialogComponent, AlertDialogData>(AlertDialogComponent, {
      panelClass: ['no-padding', 'dialog-sm'],
      closeOnNavigation: true,
      data: {
        title: translatable('Errors.FormError.Title'),
        body: translatable('Errors.FormError.Body'),
        color: 'warn',
      }
    });

    ref.afterClosed()
      .subscribe(() => {
        const selector = FORM_FIELDS
          .map(field => `${field}.ng-invalid`)
          .join(',');

        const invalidElement: HTMLElement = document.querySelector(selector);
        if (invalidElement) {
          invalidElement.scrollIntoView({behavior: 'smooth'});
          invalidElement.focus();
        }
      });
  }

  stateInvalid(): void {
    this.dialog.open<AlertDialogComponent, AlertDialogData>(AlertDialogComponent, {
      panelClass: ['no-padding', 'dialog-sm'],
      closeOnNavigation: true,
      data: {
        title: translatable('Errors.StateInvalid.Title'),
        body: translatable('Errors.StateInvalid.Body'),
        color: 'warn'
      }
    });
  }

  notifyDeleted(): void {
    this.translate.get('Generic.Deleted')
      .subscribe(message => this.snackBar.open(message, null, {
        duration: 3000
      }));
  }

  notifySaved(): void {
    this.translate.get('Generic.Saved')
      .subscribe(message => this.snackBar.open(message, null, {
        duration: 3000
      }));
  }
}
