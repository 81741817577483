import {registerLocaleData} from "@angular/common";
import {Injectable, OnDestroy} from '@angular/core';
import {MatStepperIntl} from "@angular/material";
import {TranslateService} from "@ngx-translate/core";
import * as moment from 'moment-timezone';
import {DateFnsConfigurationService} from "ngx-date-fns";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {distinctUntilChanged, map, startWith, switchMap, takeUntil} from "rxjs/operators";

import {nextLocale} from "../../utils/next-locale";

@Injectable({
  providedIn: 'root'
})
export class I18nService implements OnDestroy {
  /**
   * Get the current locale or fallback onto 'en' which is Angular default
   */
  get editorLocale(): Observable<string> {
    return this._editorLocale.asObservable();
  }

  /**
   * Get the current locale or fallback onto 'en' which is Angular default
   */
  get locale(): Observable<string> {
    return this._locale.asObservable();
  }

  get currentLocale(): string {
    return this._currentLocale;
  }

  /**
   * Get the locale used by @ngx-translate
   */
  get translateLocale(): string {
    return this.translate.currentLang || this.translate.defaultLang || 'nl';
  }

  private readonly destroy = new Subject<void>();
  private readonly _editorLocale = new ReplaySubject<string>(1);
  private readonly _locale = new ReplaySubject<string>(1);
  private _currentLocale: string;

  private readonly angularLocalesLoading: { [key: string]: Promise<{ localeData: any, localeExtraData: any, loadedLocale: string }> } = {};
  private readonly dateLocalesLoading: { [key: string]: Promise<any> } = {};
  private readonly editorLocalesLoading: { [key: string]: Promise<string> } = {};
  private readonly momentLocalesLoading: { [key: string]: Promise<string> } = {};

  constructor(private readonly dateConfig: DateFnsConfigurationService,
              private readonly stepperIntl: MatStepperIntl,
              private readonly translate: TranslateService) {
    this._editorLocale.next('nl');
    this._locale.next('nl');
  }

  /**
   * Make sure the translate observable is unsubscribed if the service gets destroyed
   */
  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();

    this._editorLocale.complete();
    this._locale.complete();
  }

  /**
   * Initialize the lang change event listener
   */
  init(): void {
    this.translate.setDefaultLang('nl');

    this.translate.onLangChange.pipe(
      map(event => event.lang),
      startWith(this.translateLocale),
      distinctUntilChanged(),
      switchMap(locale => this.loadEditorLocale(locale)),
      takeUntil(this.destroy)
    )
      .subscribe(locale => {
        this._editorLocale.next(locale);
      });

    this.translate.onLangChange.pipe(
      map(event => event.lang),
      startWith(this.translateLocale),
      distinctUntilChanged(),
      switchMap(locale => this.registerAngularLocale(locale)),
      takeUntil(this.destroy)
    )
      .subscribe(locale => {
        this._locale.next(locale);
      });

    this.translate.onLangChange.pipe(
      map(event => event.lang),
      startWith(this.translateLocale),
      distinctUntilChanged(),
      switchMap(locale => this.loadDateLocale(locale)),
      takeUntil(this.destroy)
    )
      .subscribe(localeData => {
        this.dateConfig.setLocale(localeData);
      });

    this.translate.onLangChange.pipe(
      map(event => event.lang),
      startWith(this.translateLocale),
      distinctUntilChanged(),
      switchMap(locale => this.loadMomentLocale(locale)),
      takeUntil(this.destroy)
    )
      .subscribe(locale => {
        moment.locale(locale);
      });

    this.translate.onLangChange.pipe(
      map(event => event.lang),
      startWith(this.translateLocale),
      distinctUntilChanged(),
      switchMap(() => this.translate.get('Stepper.Optional')),
      takeUntil(this.destroy)
    )
      .subscribe(label => {
        this.stepperIntl.optionalLabel = label;
        this.stepperIntl.changes.next();
      });

    this.translate.onLangChange
      .pipe(
        map(event => event.lang),
        startWith(this.translateLocale),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe(lang => {
        document.documentElement.setAttribute('lang', lang);
      });

    this._locale.pipe(takeUntil(this.destroy))
      .subscribe(locale => {
        this._currentLocale = locale;
      });

    this.setLocale(this.translate.getBrowserCultureLang());
  }

  /**
   * Update the locale with error detection and fallback
   */
  setLocale(locale: string): void {
    this.translate.use(locale)
      .subscribe({
        error: () => {
          this.setLocale(nextLocale(locale));
        }
      });
  }

  /**
   * Load the requested locale, if the locale cannot be loaded (does not exist) gracefully fall back onto culture-less,
   * if no locale could be found for the requested locale, fall back onto 'en' since this is always available.
   */
  private async loadAngularLocale(locale: string): Promise<{ localeData: any, localeExtraData: any, loadedLocale: string }> {
    if (!(locale in this.angularLocalesLoading)) {
      this.angularLocalesLoading[locale] = Promise.all([
        // tslint:disable-next-line:comment-type
        import(/* webpackMode: 'lazy-once', webpackPrefetch: true */ `@angular/common/locales/${locale}.js`)
          .then(module => module.default),
        // tslint:disable-next-line:comment-type
        import(/* webpackMode: 'lazy-once', webpackPrefetch: true */ `@angular/common/locales/extra/${locale}.js`)
          .then(module => module.default, () => null),
        Promise.resolve(locale)
      ])
        .then(([localeData, localeExtraData, loadedLocale]) => ({localeData, localeExtraData, loadedLocale}))
        .catch(() => this.loadAngularLocale(nextLocale(locale)));
    }

    return this.angularLocalesLoading[locale];
  }

  /**
   * Load the requested locale, if the locale cannot be loaded (does not exist) gracefully fall back onto culture-less,
   * if no locale could be found for the requested locale, fall back onto 'en' since this is always available.
   */
  private async loadDateLocale(locale: string): Promise<any> {
    if (!(locale in this.dateLocalesLoading)) {
      // tslint:disable-next-line:comment-type
      this.dateLocalesLoading[locale] = import(/* webpackMode: 'lazy-once', webpackPrefetch: true */ `date-fns/locale/${locale}/index.js`)
        .then(module => module.default)
        .catch(() => this.loadDateLocale(nextLocale(locale)));
    }

    return this.dateLocalesLoading[locale];
  }


  /**
   * Load the requested locale, if the locale cannot be loaded (does not exist) gracefully fall back onto culture-less,
   * if no locale could be found for the requested locale, fall back onto 'en' since this is always available.
   */
  private async loadEditorLocale(locale: string): Promise<string> {
    if (locale === 'en') {
      return locale;
    }

    if (!(locale in this.editorLocalesLoading)) {
      // tslint:disable-next-line:comment-type
      this.editorLocalesLoading[locale] = import(/* webpackMode: 'lazy-once', webpackPrefetch: true */ `tinymce-i18n/langs/${locale}.js`)
        .then(() => locale)
        .catch(() => this.loadEditorLocale(nextLocale(locale)));
    }

    return this.editorLocalesLoading[locale];
  }

  private async loadMomentLocale(locale: string): Promise<string> {
    if (locale === 'en') {
      return locale;
    }

    if (!(locale in this.momentLocalesLoading)) {
      // tslint:disable-next-line:comment-type
      this.momentLocalesLoading[locale] = import(/* webpackMode: 'lazy-once', webpackPrefetch: true */ `moment/locale/${locale}.js`)
        .then(() => locale)
        .catch(() => this.loadMomentLocale(nextLocale(locale)));
    }

    return this.momentLocalesLoading[locale];
  }

  /**
   * Register the requested locale into angular
   */
  private async registerAngularLocale(locale: string): Promise<string> {
    const result = await this.loadAngularLocale(locale);

    registerLocaleData(result.localeData, result.localeExtraData);

    return result.loadedLocale;
  }
}

export const I18N_INITIALIZER = (i18n: I18nService) => () => {
  i18n.init();
};

export const localeIdFactory = (i18n: I18nService): string => i18n.currentLocale || 'en-US';
