import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import * as ClipboardJS from 'clipboard';

@Directive({
  selector: '[evicareCopyToClipboard]'
})
export class CopyToClipboardDirective implements OnChanges, OnDestroy, OnInit {
  private clipboard: ClipboardJS;

  @Input('evicareCopyToClipboard') private readonly options: string | ClipboardJS.Options;
  @Input() private readonly copyContainer: HTMLElement;

  constructor(private readonly element: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.evicareCopyToClipboard || changes.copyContainer) {
      this.initClipboard();
    }
  }

  ngOnDestroy(): void {
    this.clipboard.destroy();
  }

  ngOnInit(): void {
    this.initClipboard();
  }

  private initClipboard(): void {
    if (this.clipboard) {
      this.clipboard.destroy();
    }

    const options: ClipboardJS.Options = (typeof this.options === 'string' ? {text: () => this.options as string} : this.options) || {};

    if (this.copyContainer) {
      options.container = this.copyContainer;
    }

    this.clipboard = new ClipboardJS(this.element.nativeElement, options);
  }
}
