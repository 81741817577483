import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {supported} from './detect-browser';
import {environment} from './environments/environment';

if (supported()) {
  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic().bootstrapModule(AppModule, {
    // TODO this should be enabled but is not yet supported by angular material so we rely on "emulated" ViewEncapsulation for the time being
    // defaultEncapsulation: ViewEncapsulation.ShadowDom,
  })
    .catch(err => {
      console.error(err)
    });
} else {
  const div = document.getElementById('old-browser-placeholder');

  document.body.innerHTML = '';
  document.body.appendChild(div);
}
