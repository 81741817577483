export const nextLocale = (locale: string): string => {
  if (/-_/.test(locale)) {
    const [, newLocale] = /^((?:[^-_]+[-_]?)+)[-_][^-_]*$/.exec(locale);

    return newLocale;
  }

  if (locale !== 'en') {
    return 'en';
  }

  return 'nl';
};
