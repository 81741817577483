import {OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {TranslateService} from "@ngx-translate/core";
import {Observable, of, throwError} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {environment} from "../../environments/environment";
import {TwoFactorShowComponent} from "../modals/two-factor-show/two-factor-show.component";
import {TwoFactorComponent} from "../modals/two-factor/two-factor.component";
import {VerifyEmailComponent} from "../modals/verify-email/verify-email.component";
import {AuthService} from "../services/auth/auth.service";

import {BaseComponent} from "./base-page.class";

export class LoginComponent extends BaseComponent implements OnInit {
  readonly version = environment.version;

  readonly form = new FormGroup({
    username: new FormControl(null, Validators.required),
    password: new FormControl(null, Validators.required),
  });

  working = false;

  constructor(protected readonly dialog: MatDialog,
              protected readonly localStorage: LocalStorage,
              translate: TranslateService,
              protected readonly authService: AuthService) {
    super(translate);
  }

  ngOnInit(): void {
    this.localStorage.getItem<string>('username', {schema: {type: 'string'}})
      .pipe(catchError(() => of<string>(null)))
      .subscribe(username => {
        this.form.controls.username.reset(username);
      });

    super.ngOnInit();
  }

  protected performLogin(otpCode?: string): Observable<boolean> {
    return this.authService.login(this.form.value.username, this.form.value.password, otpCode)
      .pipe(
        switchMap(result => {
          if (typeof result !== 'boolean') {
            if ('otp_required' in result && result.otp_required) {
              return this.requestOtp();
            }

            if ('otp_url' in result && result.otp_url) {
              return this.showOtp(result.otp_url);
            }

            if ('verified' in result && !result.verified) {
              return this.verifyEmail();
            }
          }

          return of(result);
        }),
        tap(result => {
          if (result) {
            this.localStorage.setItem('username', this.form.value.username, {type: 'string'})
              .subscribe({
                error: error => {
                  console.warn(error);
                }
              });
          }
        }),
        switchMap(result => {
          if (result) {
            return of<boolean>(true);
          }

          return this.authService.logout()
            .pipe(map(() => false));
        })
      );
  }

  protected requestOtp(): Observable<boolean> {
    const dialog = this.dialog.open(TwoFactorComponent, {
      disableClose: true,
    });

    return dialog.afterClosed()
      .pipe(switchMap(result => {
        if (result) {
          return this.performLogin(result);
        }

        return throwError(new Error('otp_required'));
      }));
  }

  protected verifyEmail(): Observable<boolean> {
    const dialog = this.dialog.open(VerifyEmailComponent, {
      disableClose: true
    });

    return dialog.afterClosed()
      .pipe(map(result => !!result));
  }

  protected showOtp(otpUrl: string): Observable<boolean> {
    const dialog = this.dialog.open(TwoFactorShowComponent, {
      closeOnNavigation: false,
      disableClose: true,
      data: {otpUrl}
    });

    return dialog.afterClosed()
      .pipe(switchMap(result => {
        if (result) {
          return this.performLogin(result);
        }

        return throwError(new Error('otp_required'));
      }));
  }
}
