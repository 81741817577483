// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// setup API url based on domain name
let apiUrl = location.protocol + '//' + location.host.replace(/^app\b/, 'api') + '/api';
// in case of dev mode: replace Angular port on Laravel port
if(apiUrl.indexOf('http://localhost') === 0) {
  apiUrl = apiUrl.replace('4200', '8080');
}

export const environment = {
  production: false,
  // apiUrl: 'https://api.evicare.nl/api',
  // apiUrl: 'https://api.evicare.appelit.com/api',
  apiUrl: apiUrl,
  // apiUrl: 'https://f0be4e02.ngrok.io/api',
  version: `${VERSION}-dev`,
  googleApiKey: 'AIzaSyCA8sQv38Ky4dW_69oZJedAKdTnLPTIxXk',
  // googleApiKey: 'AIzaSyA51aNrcP27cewkTxjKOP4NdcCWaDvaMSU',
  oneSignalId: 'ffe781f0-48ce-4358-ae9e-bf0fee3da375',
  onlineCheck: 'https://httpbin.org/anything',
  snelstartSuccessUrl: 'https://api.evicare.appelit.com/snelstart/callback',
  snelstartLinkUrl: 'https://web.snelstart.nl/couplings/activate/evicaretest',
};

// For easier debugging in development mode, you can import the following file
// to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//
// This import should be commented out in production mode because it will have a negative impact
// on performance if an error is thrown.
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
