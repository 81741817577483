import {HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

@Injectable()
export class ParamsInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const params = req.params.keys().reduce((result, paramName) => {
      const values = req.params.getAll(paramName);

      if (Array.isArray(values)) {
        if (values.length > 1) {
          if (!/(?:\[.*]|%5B.*%5D)$/.test(paramName)) {
            return values.reduce((valueResult, value) => valueResult.append(`${paramName}[]`, value), result);
          }

          return values.reduce((valueResult, value) => valueResult.append(paramName, value), result);
        }

        return result.append(paramName, values[0]);
      }

      return result.append(paramName, values);
    }, new HttpParams());

    return next.handle(req.clone({params}));
  }
}
