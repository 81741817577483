import {OnDestroy, OnInit} from "@angular/core";
import {AbstractControl} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {Subject} from "rxjs";
import {distinctUntilChanged, map, startWith, takeUntil} from "rxjs/operators";

export abstract class BaseComponent implements OnDestroy, OnInit {
  locale = 'nl';

  protected readonly destroy = new Subject<void>();
  protected readonly localeChange = new Subject<void>();

  protected constructor(protected readonly translate: TranslateService) {
  }

  ngOnInit(): void {
    this.translate.onLangChange.pipe(
      map(event => event.lang),
      startWith(this.translate.currentLang || this.translate.defaultLang || 'nl'),
      distinctUntilChanged(),
      takeUntil(this.destroy)
    )
      .subscribe(locale => {
        this.locale = locale;

        this.localeChange.next();
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();

    this.localeChange.complete();
  }

  onBlurAutocomplete<T>(event: Event, control: AbstractControl): void;
  onBlurAutocomplete<T>(event: Event, control: AbstractControl, data: Array<T>, searchKey: keyof T): void;
  onBlurAutocomplete<T>(event: Event, control: AbstractControl, data?: Array<T>, searchKey?: keyof T): void {
    const isStringOrNumber = (value: any): value is string | number => typeof value === 'string' || typeof value === 'number';

    const controlValue = control.value;
    if (isStringOrNumber(controlValue)) {
      if (searchKey && data) {
        const value1 = `${controlValue}`.toLowerCase();

        const match = data.find(item => {
          const itemValue: unknown = item[searchKey];
          if (isStringOrNumber(itemValue)) {
            const value2 = `${itemValue}`.toLowerCase();

            return value1 === value2;
          }

          return false;
        });

        if (match) {
          control.setValue(match);

          return;
        }
      }

      control.setValue(null);
    }
  }

  // noinspection JSMethodCanBeStatic
  compareById<T extends (string | number)>(lhs: { id: T }, rhs: { id: T }): boolean {
    if (typeof lhs === 'string' || typeof rhs === 'string') {
      return lhs === rhs;
    }

    return lhs && rhs ? lhs.id === rhs.id : lhs === rhs;
  }

  // noinspection JSMethodCanBeStatic
  trackByIdFn<T extends (string | number)>(_: number, item: { id: T }): T {
    return item && item.id;
  }

  // noinspection JSMethodCanBeStatic
  trackIdentityFn<T>(_: number, item: T): T {
    return item;
  }
}
