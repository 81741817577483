import {AgmCoreModule} from "@agm/core";
import {MatGoogleMapsAutocompleteModule} from "@angular-material-extensions/google-maps-autocomplete";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {NgModule} from '@angular/core';
import {MatIconRegistry, MatSnackBar, MatSnackBarModule, MatStepperModule} from "@angular/material";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {marker as translatable} from "@biesbjerg/ngx-translate-extract-marker";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {DateFnsModule} from "ngx-date-fns";
import {forkJoin} from "rxjs";

import {environment} from "../environments/environment";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BaseModule} from "./base/base.module";
import {ServicesModule} from "./services/services.module";
import {WorkBoxService} from "./services/work-box/work-box.service";

export const httpLoaderFactory = (http: HttpClient): TranslateLoader => new TranslateHttpLoader(http);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ['places']
    }),
    DateFnsModule.forRoot(),
    MatMomentDateModule,
    MatGoogleMapsAutocompleteModule.forRoot(),
    MatSnackBarModule,
    MatStepperModule,
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, deps: [HttpClient], useFactory: httpLoaderFactory},
    }),
    AppRoutingModule,
    BaseModule,
    ServicesModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(domSanitizer: DomSanitizer,
              snackBar: MatSnackBar,
              translate: TranslateService,
              matIconRegistry: MatIconRegistry,
              workBoxService: WorkBoxService) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));

    workBoxService.init();

    workBoxService.isUpdated
      .subscribe(updated => {
        if (updated === 'updated') {
          forkJoin([
            translate.get(translatable('ServiceWorker.Updated')),
            translate.get(translatable('ServiceWorker.Reload'))
          ])
            .subscribe(messages => {
              const ref = snackBar.open(messages[0], messages[1], {
                duration: 5000,
              });

              ref.onAction()
                .subscribe(() => {
                  window.location.reload()
                });
            });

          return;
        }

        if (updated === 'externalupdated') {
          translate.get(translatable('ServiceWorker.ExternalUpdated'))
            .subscribe(message => {
              if (confirm(message)) {
                window.location.reload();
              }
            });

          return;
        }
      });
  }
}
