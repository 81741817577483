import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {TranslateService} from "@ngx-translate/core";
import {finalize} from "rxjs/operators";

import {LoginComponent} from "../../classes/login-page.class";
import {AuthService} from "../../services/auth/auth.service";
import {ErrorService} from "../../services/error/error.service";

@Component({
  selector: 'evicare-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent extends LoginComponent {
  constructor(dialog: MatDialog,
              private readonly dialogRef: MatDialogRef<LoginDialogComponent>,
              localStorage: LocalStorage,
              translate: TranslateService,
              authService: AuthService,
              private readonly errorService: ErrorService) {
    super(dialog, localStorage, translate, authService);
  }

  submit(event: Event): void {
    event.preventDefault();

    if (this.form.invalid) {
      this.errorService.formInvalid();

      return;
    }

    this.working = true;

    this.performLogin()
      .pipe(finalize(() => this.working = false))
      .subscribe(
        result => {
          if (result) {
            this.dialogRef.close(true);
          }
        },
        error => {
          this.errorService.handle(error);
        }
      );
  }
}
