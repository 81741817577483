import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {QRCodeModule} from "angularx-qrcode";

import {BaseModule} from "../../base/base.module";
import {DirectivesModule} from "../../directives/directives.module";

import {TwoFactorShowComponent} from "./two-factor-show.component";

@NgModule({
  declarations: [TwoFactorShowComponent],
  entryComponents: [TwoFactorShowComponent],
  imports: [
    CommonModule,
    QRCodeModule,
    BaseModule,
    DirectivesModule
  ]
})
export class TwoFactorShowModule {
}
