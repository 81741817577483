import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BaseModule} from "../../base/base.module";
import {DirectivesModule} from "../../directives/directives.module";

import {VerifyEmailComponent} from "./verify-email.component";

@NgModule({
  declarations: [VerifyEmailComponent],
  entryComponents: [VerifyEmailComponent],
  imports: [
    CommonModule,
    BaseModule,
    DirectivesModule
  ]
})
export class VerifyEmailModule {
}
