import {FocusMonitor} from "@angular/cdk/a11y";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {
  Directive,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self
} from '@angular/core';
import {FormControl, FormGroupDirective, NgControl, NgForm} from "@angular/forms";
import {ErrorStateMatcher, MatFormFieldControl} from "@angular/material";
import {Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Directive({
  selector: '[evicareMatControlField]',
  providers: [
    {provide: MatFormFieldControl, useExisting: MatControlFieldDirective},
  ]
})
// tslint:disable-next-line:no-conflicting-lifecycle
export class MatControlFieldDirective implements DoCheck, MatFormFieldControl<boolean>, OnDestroy, OnChanges, OnInit {
  private static nextId = 0;

  @HostBinding('id') _id: string;
  @HostBinding('attr.aria-describedby') describedBy = '';

  private readonly _stateChanges = new Subject<void>();
  private readonly destroy = new Subject<void>();
  private readonly uid = `mat-control-field-${MatControlFieldDirective.nextId += 1}`;

  @Input() private readonly errorStateMatcher: ErrorStateMatcher;
  @Input() private readonly floatLabel: boolean = null;

  private _errorState = false;
  private _focused = false;
  private _required = false;
  private _value = null;

  constructor(private readonly defaultErrorStateMatcher: ErrorStateMatcher,
              private readonly elementRef: ElementRef<HTMLElement>,
              private readonly focusMonitor: FocusMonitor,
              @Self() readonly ngControl: NgControl,
              @Optional() private readonly _formGroupDirective: FormGroupDirective,
              @Optional() private readonly _ngForm: NgForm,
              @Optional() private readonly parentForm: NgForm,
              @Optional() private readonly parentFormGroup: FormGroupDirective) {
    this._id = this.uid;
  }

  get autofilled(): boolean {
    return this.ngControl && this.ngControl.control && !this.ngControl.control.dirty;
  }

  get controlType(): string {
    return 'evicare-mat-control-field';
  }

  @Input() get disabled(): boolean {
    return this.ngControl && this.ngControl.control && this.ngControl.control.disabled;
  }

  set disabled(value: boolean) {
    if (this.ngControl && this.ngControl.control) {
      const disabled = coerceBooleanProperty(value);

      if (disabled) {
        this.ngControl.control.disable();
      } else {
        this.ngControl.control.enable();
      }

      this._stateChanges.next();
    }
  }

  get empty(): boolean {
    const value = this.value;

    return value === null || typeof value === 'undefined';
  }

  get errorState(): boolean {
    return this._errorState;
  }

  @Input() get id(): string {
    return this._id || this.uid;
  }

  set id(value: string) {
    this._id = value;
  }

  get focused(): boolean {
    return this._focused;
  }

  get placeholder(): string {
    return null;
  }

  @Input() get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);

    this._stateChanges.next();
  }

  get shouldLabelFloat(): boolean {
    return this.floatLabel || (this.floatLabel && !this.empty);
  }

  get stateChanges(): Observable<void> {
    return this._stateChanges.asObservable();
  }

  get value(): any {
    return !!(this.ngControl && this.ngControl.control && this.ngControl.control.value);
  }

  set value(value: any) {
    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.setValue(value);
    }
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  ngOnChanges(): void {
    this._stateChanges.next();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();

    this._stateChanges.complete();

    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  ngOnInit(): void {
    this.focusMonitor.monitor(this.elementRef.nativeElement, true)
      .pipe(takeUntil(this.destroy))
      .subscribe(focus => {
        this._focused = !!focus;

        setTimeout(() => {
          this._stateChanges.next();
        });
      });

    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.valueChanges.pipe(
        takeUntil(this.destroy)
      )
        .subscribe(
          value => {
            this._value = value;

            this._stateChanges.next();
          }
        );
    } else {
      throw new Error('Missing ngControl, evicareMatControlField requires a ngModel or formControl to be present on the element');
    }
  }

  setDescribedByIds(ids: Array<string>): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(): void {
    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.markAsTouched();
    }
  }

  private updateErrorState(): void {
    const oldState = this.errorState;
    const parent = this.parentFormGroup || this.parentForm;
    const matcher = this.errorStateMatcher || this.defaultErrorStateMatcher;
    const control = this.ngControl ? this.ngControl.control as FormControl : null;
    const newState = matcher.isErrorState(control, parent);

    if (newState !== oldState) {
      this._errorState = newState;
      this._stateChanges.next();
    }
  }
}
