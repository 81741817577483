import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {Directive, Input} from '@angular/core';
import {MatFormFieldControl} from "@angular/material";

@Directive({
  selector: '[evicareMatRequired]'
})
export class MatRequiredDirective {
  constructor(private readonly formField: MatFormFieldControl<any>) {
  }

  @Input('evicareMatRequired') set required(value: boolean) {
    (this.formField as any).required = coerceBooleanProperty(value);
  }

  get required(): boolean {
    return this.formField.required;
  }
}
