import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

import {AppointmentModel, RegularAppointmentCreateModel} from "../../models/appointment.model";
import {PaginatedResponse} from "../../models/paginated.model";
import {buildUrl} from "../../utils/build-url";

import {AddressModel} from "./api.types";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private readonly http: HttpClient) {
  }

  address(postalCode: string, streetNumber?: string, page = 1): Observable<PaginatedResponse<AddressModel>> {
    return this.http.post<PaginatedResponse<AddressModel>>(buildUrl('address'), {
      postal_code: postalCode,
      street_number: streetNumber ? streetNumber : '',
      page: `${page}`
    });
  }

  sessionKey(publicKey: string): Observable<{ public_key: string, session_info: string }> {
    return this.http.post<{ public_key: string, session_info: string }>(buildUrl('session'), {
      session_key: publicKey
    }, {
      withCredentials: true
    })
  }

  appointment(appointment: RegularAppointmentCreateModel): Observable<AppointmentModel> {
    const headers: { [key: string]: string | Array<string> } = {};
    if (appointment.client_mode) {
      headers['X-Skip-Auth'] = '1';
    }

    return this.http.post<AppointmentModel>(buildUrl('appointment'), appointment, {headers});
  }
}
