import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef, MatSnackBar} from "@angular/material";
import {TranslateService} from "@ngx-translate/core";
import {finalize, switchMap, tap} from "rxjs/operators";

import {ErrorService} from "../../services/error/error.service";
import {UserService} from "../../services/user/user.service";

@Component({
  selector: 'evicare-verify',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent {
  readonly form = new FormGroup({
    token: new FormControl(null, [Validators.required]),
  });

  working = false;

  constructor(private readonly snackBar: MatSnackBar,
              private readonly dialogRef: MatDialogRef<VerifyEmailComponent>,
              private readonly translate: TranslateService,
              private readonly errorService: ErrorService,
              private readonly userService: UserService) {
  }

  resend(event: Event): void {
    event.preventDefault();

    this.working = true;

    this.userService.resendVerification()
      .pipe(
        switchMap(() => this.translate.get('VerifyEmail.ResendSuccess')),
        tap(message => this.snackBar.open(message, null, {duration: 3000})),
        finalize(() => this.working = false)
      )
      .subscribe({
        error: reason => {
          this.errorService.handle(reason);
        }
      });
  }

  submit(event: Event): void {
    event.preventDefault();

    if (this.form.invalid) {
      this.errorService.formInvalid();

      return;
    }

    this.working = true;

    this.userService.verifyEmail(this.form.value.token)
      .pipe(
        switchMap(() => this.translate.get('VerifyEmail.Success')),
        tap(message => this.snackBar.open(message, null, {duration: 3000})),
        finalize(() => this.working = false)
      )
      .subscribe(
        () => {
          this.dialogRef.close(true);
        },
        error => {
          this.errorService.handle(error);
        }
      )
  }
}
