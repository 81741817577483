import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {first, map} from "rxjs/operators";

import {WorkBoxService} from "../services/work-box/work-box.service";

@Injectable({
  providedIn: 'root'
})
export class ApplicationUpdatedGuard implements CanActivate, CanActivateChild {
  constructor(protected readonly workBoxService: WorkBoxService) {
  }

  private static get currentUrl(): string {
    return `${window.location.pathname}${window.location.search}${window.location.hash}`;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.ready(state.url);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.ready(state.url);
  }

  protected ready(url: string): Observable<boolean> {
    return this.workBoxService.isUpdated
      .pipe(
        first(),
        map(updated => {
          if (updated) {
            if (ApplicationUpdatedGuard.currentUrl === url) {
              window.location.reload();
            } else {
              window.location.href = `${window.location.origin}${url}`;
            }

            return false;
          }

          return true;
        })
      );
  }
}
