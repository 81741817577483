import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";

import {NotFoundComponent} from "./base/not-found/not-found.component";
import {ApplicationUpdatedGuard} from "./guards/application-updated.guard";
import {SelectivePreloadingStrategyService} from "./services/selective-preloading-strategy/selective-preloading-strategy.service";

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule',
    canActivate: [ApplicationUpdatedGuard],
    data: {preload: true}
  },
  {
    path: '',
    loadChildren: './core/core.module#CoreModule',
    canActivate: [ApplicationUpdatedGuard],
  },
  {path: '**', component: NotFoundComponent, data: {animation: 'NotFound'}}
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      // enableTracing: !environment.production,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      relativeLinkResolution: "corrected",
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: SelectivePreloadingStrategyService,
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
