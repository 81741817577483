import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material";

import {ErrorService} from "../../services/error/error.service";

@Component({
  selector: 'evicare-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.scss'],
})
export class TwoFactorComponent {
  readonly form = new FormGroup({
    code: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]{6}$/)]),
  });

  constructor(private readonly dialogRef: MatDialogRef<TwoFactorComponent>,
              private readonly errorService: ErrorService) {
  }

  submit(event: Event): void {
    event.preventDefault();

    if (this.form.invalid) {
      this.errorService.formInvalid();

      return;
    }

    this.dialogRef.close(this.form.value.code);
  }
}
