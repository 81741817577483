import {Injectable} from '@angular/core';
import {PreloadingStrategy, Route} from "@angular/router";
import {Observable, of, timer} from "rxjs";
import {flatMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SelectivePreloadingStrategyService implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && 'preload' in route.data) {
      // tslint:disable-next-line:no-boolean-literal-compare
      if (route.data.preload === true) {
        return load();
      }

      // tslint:disable-next-line:no-boolean-literal-compare
      if (typeof route.data.preload === 'object' && route.data.preload.enable === true) {
        // tslint:disable-next-line:no-boolean-literal-compare
        if (route.data.preload.delay === true) {
          return timer(100)
            .pipe(flatMap(load));
        }

        if (typeof route.data.preload.delay === 'number' && route.data.preload.delay > 0) {
          return timer(route.data.preload.delay)
            .pipe(flatMap(load));
        }

        return load();
      }
    }

    return of<any>(null);
  }
}
