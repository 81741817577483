import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import * as Url from 'url-parse';

import {ErrorService} from "../../services/error/error.service";
import {UserService} from "../../services/user/user.service";

@Component({
  selector: 'evicare-two-factor-show',
  templateUrl: './two-factor-show.component.html',
  styleUrls: ['./two-factor-show.component.scss']
})
export class TwoFactorShowComponent {
  readonly form = new FormGroup({
    code: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]{6}$/)]),
  });

  otpUrl: string;
  otpSecret: string;

  constructor(private readonly dialog: MatDialogRef<TwoFactorShowComponent>,
              private readonly errorService: ErrorService,
              private readonly userService: UserService,
              @Inject(MAT_DIALOG_DATA) data: { otpUrl: string }) {
    this.otpUrl = data.otpUrl;

    const url = new Url(data.otpUrl, {}, true);
    this.otpSecret = url.query.secret;
  }

  close(): void {
    this.dialog.close();
  }

  submit(event: Event): void {
    event.preventDefault();

    if (this.form.invalid) {
      this.errorService.formInvalid();

      return;
    }

    this.dialog.close(this.form.value.code);
  }
}
