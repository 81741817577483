import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import {Injectable, Injector} from '@angular/core';
import {fromEvent, Observable, of, throwError, timer} from "rxjs";
import {first, map, retryWhen, switchMap, tap} from "rxjs/operators";

import {environment} from "../../../environments/environment";

@Injectable()
export class NetworkErrorInterceptor implements HttpInterceptor {
  constructor(private readonly injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let counter = 0;

    return next.handle(req)
      .pipe(retryWhen(errors => errors.pipe(
        switchMap((error) => {
          if (!(error instanceof HttpErrorResponse) || error.status !== 0 || counter > 2 || error.url === environment.onlineCheck) {
            return throwError(error)
          }

          counter = counter + 1;

          // If the browser has support for the "onLine" api and event, and we seem to be offline, use it to trigger a refresh
          if ('onLine' in navigator && !navigator.onLine) {
            return fromEvent(window, 'online')
              .pipe(
                first(),
                tap(() => counter = 0)
              );
          }

          // Should counter "network changed issues"
          if (counter === 1) {
            return of<undefined>(undefined);
          }

          const httpClient = this.injector.get(HttpClient);

          // Fetch an external url to verify if the network itself is reachable or if there is any other (server side) cause
          return httpClient.get(environment.onlineCheck)
            .pipe(
              retryWhen(checkErrors => checkErrors.pipe(
                switchMap((checkError, count) => {
                  if (checkError instanceof HttpErrorResponse && checkError.status === 0) {
                    return timer(Math.min((count + 1) * 500, 10000));
                  }

                  return throwError(error);
                })
              )),
              map(() => undefined)
            );
        })
      )));
  }
}
