import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BlobSrcDirective} from './blob-src/blob-src.directive';
import {CopyToClipboardDirective} from "./copy-to-clipboard/copy-to-clipboard.directive";
import {MatControlFieldDirective} from './mat-control-field/mat-control-field.directive';
import {MatRequiredDirective} from './mat-required/mat-required.directive';

@NgModule({
  declarations: [
    CopyToClipboardDirective,
    MatControlFieldDirective,
    MatRequiredDirective,
    BlobSrcDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CopyToClipboardDirective,
    MatControlFieldDirective,
    MatRequiredDirective,
    BlobSrcDirective,
  ]
})
export class DirectivesModule {
}
