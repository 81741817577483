import {isPlatformBrowser} from "@angular/common";
import {Inject, Injectable, NgZone, PLATFORM_ID} from '@angular/core';
import {fromEvent, Observable, of, race, ReplaySubject, timer} from "rxjs";
import {first, map} from "rxjs/operators";

import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WorkBoxService {
  private static workBoxInstalled = false;

  private oneSignalUpdated = false;
  private updateOnInit = false;

  private readonly isUpdated$ = new ReplaySubject<'updated' | 'externalupdated' | false>(1);

  constructor(private readonly ngZone: NgZone,
              @Inject(PLATFORM_ID) private readonly platformId: object) {
  }

  get isUpdated(): Observable<'updated' | 'externalupdated' | false> {
    return this.isUpdated$.asObservable();
  }

  init(): void {
    this.initialize()
      .catch(reason => {
        console.warn(reason);
      });
  }

  private async initialize(): Promise<void> {
    if (WorkBoxService.workBoxInstalled) {
      return;
    }

    WorkBoxService.workBoxInstalled = true;

    if (!environment.production || !isPlatformBrowser(this.platformId) || !('serviceWorker' in navigator)) {
      this.isUpdated$.next(false);

      return;
    }

    const {Workbox} = await import('workbox-window');

    let sdkVersion = typeof localStorage.oneSignalVersion === 'string'
      ? parseInt(localStorage.oneSignalVersion, 10)
      : 0;
    if (typeof sdkVersion !== 'number' || isNaN(sdkVersion) || !isFinite(sdkVersion)) {
      sdkVersion = 0;
    }

    const wb = new Workbox(`/service-worker.js?appId=${environment.oneSignalId}?sdkVersion=${sdkVersion}`);

    wb.addEventListener('activated', (event) => {
      if (event.isUpdate) {
        this.updated('updated', event.sw);
      }
    });

    wb.addEventListener('externalactivated', event => {
      this.updated('externalupdated', event.sw);
    });

    try {
      const registration = await wb.register();

      if (!registration.active) {
        this.isUpdated$.next(false);

        return;
      }

      race(
        timer(300).pipe(map(() => false)),
        registration.installing
          ? of(true)
          : fromEvent(registration, 'updatefound').pipe(map(() => true))
      )
        .pipe(first())
        .subscribe(updated => {
          if (updated) {
            this.updateOnInit = true;

            return;
          }

          this.isUpdated$.next(false);
        });
    } catch (e) {
      console.warn(e);

      this.isUpdated$.next(false);
    }
  }

  private updated(message: 'updated' | 'externalupdated', sw: ServiceWorker): void {
    if (sw.scriptURL.indexOf('/service-worker-updater.js') !== -1) {
      this.oneSignalUpdated = true;

      return;
    }

    const urlSegments = sw.scriptURL.split(/[?&]/).slice(1);
    const urlParams = urlSegments.map(segment => segment.split('='))
      .reduce<{ [key: string]: string }>((result, current) => {
        result[current[0]] = current.slice(1).join('=');

        return result;
      }, {});

    if ('sdkVersion' in urlParams) {
      const urlVersion = urlParams.sdkVersion && parseInt(urlParams.sdkVersion, 10);
      const savedVersion = localStorage.oneSignalVersion && parseInt(localStorage.oneSignalVersion, 10);

      if (urlVersion !== savedVersion) {
        localStorage.oneSignalVersion = urlParams.sdkVersion;

        return;
      }
    }

    if (sw.scriptURL.indexOf('/service-worker.js') !== -1) {
      if (this.oneSignalUpdated) {
        this.oneSignalUpdated = false;

        return;
      }

      if (this.updateOnInit) {
        window.location.reload();

        return;
      }

      this.ngZone.run(() => {
        this.isUpdated$.next(message);
      });
    }
  }
}
