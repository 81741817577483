import {HttpClient} from "@angular/common/http";
import {Injectable, OnDestroy} from '@angular/core';
import {combineLatest, Observable, of, ReplaySubject, Subject, throwError, timer} from "rxjs";
import {first, map, retryWhen, skipWhile, switchMap, tap} from "rxjs/operators";

import {PaginatedResponse} from "../../models/paginated.model";
import {buildUrl} from "../../utils/build-url";
import {memzero} from "../../utils/memzero";
import {AssociationListParams, AssociationModel} from "../association/association.types";
import {IndicationListParams, IndicationModel} from "../indication/indication.types";
import {LocationListParams, LocationModel} from "../location/location.types";
import {SodiumService} from "../sodium/sodium.service";
import {HashAlgorithm} from "../sodium/sodium.types";
import {DossierListParams, DossierModel} from "../specialist/dossier/dossier.types";
import {SpecialistListParams, SpecialistModel} from "../specialist/specialist.types";
import {TokenStoreService} from "../token/token-store.service";

import {
  LedgerModel,
  UserCreateModel,
  UserListParams,
  UserLogListParams,
  UserModel,
  UserUpdateModel
} from "./user.types";

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  /**
   * Watch the current me
   */
  private meObservable: Observable<UserModel>;

  private readonly _me = new ReplaySubject<UserModel>(1);
  private readonly _refresh = new Subject<void>();
  private readonly _refreshing = new ReplaySubject<boolean>(1);

  constructor(private readonly http: HttpClient,
              private readonly tokenService: TokenStoreService,
              private readonly sodium: SodiumService) {
  }

  get me(): Observable<UserModel> {
    if (!this.meObservable) {
      this.meObservable = this._me.pipe(
        switchMap(
          user => this._refreshing.pipe(
            skipWhile(refreshing => refreshing),
            map(() => user)
          )
        )
      );

      combineLatest([
        this.tokenService.authenticatedChange,
        this._refresh
      ])
        .pipe(
          switchMap(([authenticated]) => {
            if (!authenticated) {
              return of<UserModel>(null);
            }

            this._refreshing.next(true);

            return this.getUser()
              .pipe(
                retryWhen(errors => errors.pipe(
                  tap(error => {
                    console.warn(error);
                  }),
                  switchMap((_, count) => timer((count + 1) * 500))
                ))
              );
          }),
        )
        .subscribe(user => {
          this._me.next(user);

          this._refreshing.next(false);
        });

      this.refresh();
    }

    return this.meObservable;
  }

  ngOnDestroy(): void {
    this._me.complete();
    this._refresh.complete();
    this._refreshing.complete();
  }

  list(params?: UserListParams): Observable<PaginatedResponse<UserModel>> {
    return this.http.get<PaginatedResponse<UserModel>>(buildUrl('user'), {params});
  }

  lookup(params?: UserListParams): Observable<PaginatedResponse<UserModel>> {
    return this.http.post<PaginatedResponse<UserModel>>(buildUrl('user', 'search'), params);
  }

  show(id: string): Observable<UserModel> {
    return this.http.get<UserModel>(buildUrl('user', id));
  }

  create(data?: UserCreateModel): Observable<UserModel> {
    return this.http.post<UserModel>(buildUrl('user'), data);
  }

  update(id: string, data?: UserUpdateModel): Observable<UserModel> {
    return this.http.put<UserModel>(buildUrl('user', id), data);
  }

  delete(id: string): Observable<undefined> {
    return this.http.delete<undefined>(buildUrl('user', id));
  }

  resendVerification(id?: string): Observable<undefined> {
    const url = id
      ? buildUrl('user', id, 'resend_verification')
      : buildUrl('user', 'resend_verification');

    return this.http.post<undefined>(url, null);
  }

  resetOtp(id: string): Observable<undefined> {
    return this.http.post<undefined>(buildUrl('user', id, 'reset_otp'), null);
  }

  associations(id: string, params?: { per_page: '0' } & AssociationListParams): Observable<Array<AssociationModel>>;
  associations(id: string, params?: { per_page?: string } & AssociationListParams): Observable<PaginatedResponse<AssociationModel>>;
  associations(id: string, params?: AssociationListParams): Observable<Array<AssociationModel> | PaginatedResponse<AssociationModel>> {
    return this.http.get<Array<AssociationModel> | PaginatedResponse<AssociationModel>>(buildUrl('user', id, 'association'), {params});
  }

  associationsLookup(id: string, params?: { per_page: '0' } & AssociationListParams): Observable<Array<AssociationModel>>;
  associationsLookup(id: string, params?: { per_page?: string } & AssociationListParams): Observable<PaginatedResponse<AssociationModel>>;
  associationsLookup(id: string, params?: AssociationListParams): Observable<Array<AssociationModel> | PaginatedResponse<AssociationModel>> {
    return this.http.post<Array<AssociationModel> | PaginatedResponse<AssociationModel>>(buildUrl('user', id, 'association', 'search'), params);
  }

  dossiers(id: string, params?: DossierListParams): Observable<PaginatedResponse<DossierModel>> {
    return this.http.get<PaginatedResponse<DossierModel>>(buildUrl('user', id, 'dossier'), {params});
  }

  dossiersLookup(id: string, params?: DossierListParams): Observable<PaginatedResponse<DossierModel>> {
    return this.http.post<PaginatedResponse<DossierModel>>(buildUrl('user', id, 'dossier', 'search'), params);
  }

  indications(id: string, params?: IndicationListParams): Observable<PaginatedResponse<IndicationModel>> {
    return this.http.get<PaginatedResponse<IndicationModel>>(buildUrl('user', id, 'indication'), {params});
  }

  indicationsLookup(id: string, params?: IndicationListParams): Observable<PaginatedResponse<IndicationModel>> {
    return this.http.post<PaginatedResponse<IndicationModel>>(buildUrl('user', id, 'indication', 'search'), params);
  }

  locations(id: string, params?: LocationListParams): Observable<PaginatedResponse<LocationModel>> {
    return this.http.get<PaginatedResponse<LocationModel>>(buildUrl('user', id, 'location'), {params});
  }

  locationsLookup(id: string, params?: LocationListParams): Observable<PaginatedResponse<LocationModel>> {
    return this.http.post<PaginatedResponse<LocationModel>>(buildUrl('user', id, 'location', 'search'), params);
  }

  logs(id: string, params: UserLogListParams): Observable<PaginatedResponse<LedgerModel>> {
    return this.http.get<PaginatedResponse<LedgerModel>>(buildUrl('user', id, 'log'), {params});
  }

  logsLookup(id: string, params: UserLogListParams): Observable<PaginatedResponse<LedgerModel>> {
    return this.http.post<PaginatedResponse<LedgerModel>>(buildUrl('user', id, 'log', 'search'), params);
  }

  specialists(id: string, params?: { per_page: '0' } & SpecialistListParams): Observable<Array<SpecialistModel>>;
  specialists(id: string, params?: { per_page?: string } & SpecialistListParams): Observable<PaginatedResponse<SpecialistModel>>;
  specialists(id: string, params?: SpecialistListParams): Observable<Array<SpecialistModel> | PaginatedResponse<SpecialistModel>> {
    return this.http.get<Array<SpecialistModel> | PaginatedResponse<SpecialistModel>>(buildUrl('user', id, 'specialist'), {params});
  }

  specialistsLookup(id: string, params?: { per_page: '0' } & SpecialistListParams): Observable<Array<SpecialistModel>>;
  specialistsLookup(id: string, params?: { per_page?: string } & SpecialistListParams): Observable<PaginatedResponse<SpecialistModel>>;
  specialistsLookup(id: string, params?: SpecialistListParams): Observable<Array<SpecialistModel> | PaginatedResponse<SpecialistModel>> {
    return this.http.post<Array<SpecialistModel> | PaginatedResponse<SpecialistModel>>(buildUrl('user', id, 'specialist', 'search'), params);
  }

  can(permission: 'list' | 'create'): Observable<{ authorized: boolean }>;
  can(permission: 'view' | 'update' | 'delete' | 'list-logs' | 'reset-otp', id: string): Observable<{ authorized: boolean }>;
  can(permission: 'list' | 'create' | 'view' | 'update' | 'delete' | 'list-logs' | 'reset-otp', id?: string): Observable<{ authorized: boolean }> {
    if (permission === 'list' || permission === 'create') {
      return this.http.get<{ authorized: boolean }>(buildUrl('user', 'can', permission));
    }

    return this.http.get<{ authorized: boolean }>(buildUrl('user', 'can', permission, id));
  }

  // Authenticated user
  /**
   * Get the current me
   */
  get(): Observable<UserModel> {
    return this.me.pipe(first());
  }

  getOrFail(): Observable<UserModel> {
    return this.tokenService.authenticated.pipe(
      switchMap(authenticated => {
        if (!authenticated) {
          return throwError('not_authenticated');
        }

        return this.get();
      })
    );
  }

  /**
   * Update the current me's password
   */
  updatePassword(currentPassword: string, newPassword: string): Observable<boolean> {
    return this.getOrFail()
      .pipe(
        switchMap(
          user => this.generatePassword(newPassword)
            .pipe(map(password => ({user, password})))
        ),
        switchMap(async ({user, password}) => {
          const signature = await this.sodium.toHex(
            await this.sodium.passwordSignature(
              password.public_key,
              currentPassword,
              user.salt,
              user.ops_limit,
              user.mem_limit,
              user.algorithm
            )
          );

          return {
            ...password,
            signature,
          };
        }),
        switchMap(body => this.http.post<undefined>(buildUrl('me', 'password'), body)),
        tap(() => {
          this.refresh();
        }),
        map(() => true)
      );
  }

  verifyEmail(token: string): Observable<boolean> {
    return this.getOrFail()
      .pipe(
        switchMap(user => {
          if (user.verified) {
            return of<UserModel>(user);
          }

          return this.http.post<undefined>(buildUrl('me', 'verify'), {token})
            .pipe(
              tap(() => {
                this.refresh();
              })
            );
        }),
        map(() => true)
      );
  }

  /**
   * Trigger a re-fetch of the current me
   */
  refresh(): void {
    this._refresh.next();
  }

  // Helper
  generatePassword(password: string): Observable<{ public_key: string, salt: string, ops_limit: number, mem_limit: number, algorithm: HashAlgorithm }> {
    return of(null)
      .pipe(
        switchMap(() => this.sodium.deriveKey(password)),
        switchMap(async derivedKey => {
          memzero(derivedKey.key.privateKey);

          return {
            public_key: await this.sodium.toHex(derivedKey.key.publicKey),
            salt: await this.sodium.toHex(derivedKey.salt),
            ops_limit: derivedKey.opsLimit,
            mem_limit: derivedKey.memLimit,
            algorithm: derivedKey.algorithm,
          };
        })
      );
  }

  private getUser(): Observable<UserModel> {
    return this.http.get<UserModel>(buildUrl('me'));
  }
}
