import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable, Injector} from '@angular/core';
import {MatSnackBar} from "@angular/material";
import {marker as translatable} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslateService} from "@ngx-translate/core";
import {forkJoin, Observable, race, throwError, timer} from "rxjs";
import {finalize, first, map, publishReplay, refCount, retryWhen, switchMap} from "rxjs/operators";

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {
  private message: Observable<undefined>;

  constructor(private readonly injector: Injector,
              private readonly snackBar: MatSnackBar) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.message) {
      return this.message.pipe(switchMap(() => this.intercept(req, next)));
    }

    return next.handle(req)
      .pipe(retryWhen(errors => errors.pipe(
        switchMap((error, count) => {
          if (!(error instanceof HttpErrorResponse) || error.status !== 503) {
            return throwError(error)
          }

          if (!this.message) {
            const delay = Math.min(count + 1, 60);
            const duration = delay * 60000;
            const translate = this.injector.get(TranslateService);

            this.message = forkJoin([
              translate.get(translatable("Maintenance.Message"), {delay}),
              translate.get(translatable("Maintenance.Retry")),
            ])
              .pipe(
                map(messages => this.snackBar.open(messages[0], messages[1], {duration})),
                switchMap(ref => race(
                  ref.afterDismissed(),
                  timer(duration)
                )),
                map(() => undefined),
                first(),
                finalize(() => this.message = null),
                publishReplay(1),
                refCount()
              );
          }

          return this.message;
        })
      )));
  }
}
