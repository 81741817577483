import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {APP_INITIALIZER, Injector, LOCALE_ID, NgModule} from "@angular/core";
import {MatDialog, MatDialogModule, MatSnackBar, MatSnackBarModule} from "@angular/material";

import {LoginDialogModule} from "../modals/login-dialog/login-dialog.module";

import {AuthenticationInterceptor} from "./authentication-interceptor/authentication-interceptor.service";
import {EncryptionInterceptor} from "./encryption-interceptor/encryption-interceptor.service";
import {FileReaderService} from "./file-reader/file-reader.service";
import {I18N_INITIALIZER, I18nService, localeIdFactory} from "./i18n/i18n.service";
import {MaintenanceInterceptor} from "./maintenance-interceptor/maintenance-interceptor.service";
import {NetworkErrorInterceptor} from "./network-error-interceptor/network-error-interceptor.service";
import {ParamsInterceptor} from "./params-interceptor/params-interceptor.service";
import {ScopeInterceptor} from "./scope-interceptor/scope-interceptor.service";
import {SodiumService} from "./sodium/sodium.service";
import {TokenStoreService} from "./token/token-store.service";

@NgModule({
  imports: [
    MatDialogModule,
    MatSnackBarModule,
    LoginDialogModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [I18nService],
      useFactory: I18N_INITIALIZER,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      deps: [I18nService],
      useFactory: localeIdFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      deps: [TokenStoreService],
      useClass: ScopeInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      deps: [Injector],
      useClass: NetworkErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      deps: [Injector, MatSnackBar],
      useClass: MaintenanceInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      deps: [Injector, FileReaderService, SodiumService],
      useClass: EncryptionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      deps: [Injector, MatDialog, TokenStoreService],
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ParamsInterceptor,
      multi: true
    },
  ],
})
export class ServicesModule {
}
