import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatProgressSpinnerModule
} from "@angular/material";
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";

import {DirectivesModule} from "../directives/directives.module";

import {AlertDialogComponent} from "./alert-dialog/alert-dialog.component";
import {ConfirmDialogComponent} from "./confirm-dialog/confirm-dialog.component";
import {NotFoundComponent} from "./not-found/not-found.component";
import {PrivacyDialogComponent} from "./privacy-dialog/privacy-dialog.component";
import {TermsDialogComponent} from "./terms-dialog/terms-dialog.component";

@NgModule({
  declarations: [
    AlertDialogComponent,
    ConfirmDialogComponent,
    NotFoundComponent,
    PrivacyDialogComponent,
    TermsDialogComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressSpinnerModule,
    FormsModule,
    RouterModule,
    DirectivesModule
  ],
  exports: [
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    NotFoundComponent,
    PrivacyDialogComponent,
    TermsDialogComponent,
    MatProgressSpinnerModule,
    FormsModule
  ],
  entryComponents: [
    AlertDialogComponent,
    ConfirmDialogComponent,
    PrivacyDialogComponent,
    TermsDialogComponent,
  ]
})
export class BaseModule {
}
