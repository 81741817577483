import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {from, Observable, of, Subject, throwError} from "rxjs";
import {catchError, finalize, map, switchMap, tap} from "rxjs/operators";

import {UserRole} from "../../auth/auth.types";
import {buildUrl} from "../../utils/build-url";
import {memzero} from "../../utils/memzero";
import {SodiumService} from "../sodium/sodium.service";
import {TokenStoreService} from "../token/token-store.service";

import {AuthChallengeResponse, TokenResponse} from "./auth.types";

export type LoginResponse = { otp_required: boolean } | { otp_url: string } | { verified: boolean } | boolean;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly reloadRole$ = new Subject<void>();

  private _token: string;

  constructor(private readonly http: HttpClient,
              private readonly sodium: SodiumService,
              private readonly tokenStore: TokenStoreService) {
  }

  get authenticated(): Observable<boolean> {
    return this.tokenStore.authenticated;
  }

  get authenticatedChange(): Observable<boolean> {
    return this.tokenStore.authenticatedChange;
  }

  get role(): Observable<{ role: UserRole, roleId?: string }> {
    return this.tokenStore.role;
  }

  get roleChange(): Observable<{ role: UserRole, roleId?: string }> {
    return this.tokenStore.roleChange;
  }

  get reloadRole(): Observable<void> {
    return this.reloadRole$.asObservable();
  }

  register(email: string, password: string, username?: string): Observable<boolean> {
    return of(null)
      .pipe(
        switchMap(() => this.sodium.deriveKey(password)),
        switchMap(async derivedKey => {
          memzero(derivedKey.key.privateKey);

          return {
            public_key: await this.sodium.toHex(derivedKey.key.publicKey),
            salt: await this.sodium.toHex(derivedKey.salt),
            ops_limit: derivedKey.opsLimit,
            mem_limit: derivedKey.memLimit,
            algorithm: derivedKey.algorithm,
          }
        }),
        switchMap(keyData => this.http.post<TokenResponse>(buildUrl('auth', 'register'), {
          ...keyData,
          username: username || email,
          email,
        })),
        switchMap(token => this.tokenStore.setToken(token))
      );
  }

  login(username: string, password: string, otpCode?: string): Observable<LoginResponse> {
    return this.getToken()
      .pipe(
        switchMap(
          token => this.http.post<AuthChallengeResponse>(buildUrl('auth', 'challenge'), {username}, {
            headers: {'X-Token': token}
          })
            .pipe(
              switchMap(challengeResponse => {
                if (!otpCode) {
                  if (challengeResponse.otp_required) {
                    return of<{ otp_required: true }>({otp_required: true});
                  }

                  if (challengeResponse.otp_code) {
                    // tslint:disable-next-line:no-boolean-literal-compare
                    const otpPassword = challengeResponse.old_password === true
                      ? token
                      : password;

                    return from(this.sodium.fromHex(challengeResponse.otp_code))
                      .pipe(
                        switchMap(otpUrl => this.sodium.passwordDecrypt(
                          otpUrl,
                          otpPassword,
                          challengeResponse.salt,
                          challengeResponse.ops_limit,
                          challengeResponse.mem_limit,
                          challengeResponse.algorithm
                        )),
                        catchError(error => {
                          console.warn(error);

                          return throwError(new Error('invalid_password'));
                        }),
                        switchMap(otpUrl => this.sodium.toString(otpUrl)),
                        map(otpUrl => ({otp_url: otpUrl}))
                      );
                  }
                }

                let observable: Observable<{ body: { username: string, password: string } | { response: string }, headers: { 'X-Token': string, 'X-Challenge-Info'?: string } }>;

                // tslint:disable-next-line:no-boolean-literal-compare
                if (challengeResponse.old_password !== false) {
                  observable = of({
                    body: {username, password},
                    headers: {'X-Token': token},
                  });
                } else {
                  observable = from(this.sodium.fromHex(challengeResponse.challenge))
                    .pipe(
                      switchMap(
                        challenge => from(this.sodium.passwordDecrypt(
                          challenge,
                          password,
                          challengeResponse.salt,
                          challengeResponse.ops_limit,
                          challengeResponse.mem_limit,
                          challengeResponse.algorithm
                        ))
                          .pipe(
                            finalize(() => {
                              memzero(challenge);
                            })
                          )
                      ),
                      catchError(error => {
                        console.warn(error);

                        return throwError(new Error('invalid_password'));
                      }),
                      switchMap(
                        nonce => from(this.sodium.toHex(nonce))
                          .pipe(
                            finalize(() => {
                              memzero(nonce)
                            })
                          )
                      ),
                      map(response => ({
                        body: {response},
                        headers: {
                          'X-Token': token,
                          'X-Challenge-Info': challengeResponse.challenge_info
                        }
                      }))
                    );
                }

                return observable.pipe(
                  switchMap(
                    ({body, headers}) => this.http.post<TokenResponse>(
                      buildUrl('auth', 'response'), {
                        ...body,
                        otp_code: otpCode
                      },
                      {headers}
                    )
                  ),
                  switchMap(tokenResponse => this.tokenStore.setToken(tokenResponse)),
                  map(result => {
                    if (result && !challengeResponse.verified) {
                      return {verified: false};
                    }

                    return result;
                  })
                );
              })
            )
        )
      );
  }

  logout(): Observable<boolean> {
    return this.tokenStore.token.pipe(
      switchMap(token => {
        if (!token) {
          return of<boolean>(true);
        }

        return this.http.post<undefined>(buildUrl('auth', 'logout'), null, {
          headers: {
            Authorization: `${token.type} ${token.token}`,
          },
        })
          .pipe(
            switchMap(() => this.tokenStore.setToken(null)),
            catchError(() => this.tokenStore.setToken(null))
          );
      })
    );
  }

  forgot(email: string): Observable<undefined> {
    return this.http.post<undefined>(buildUrl('auth', 'forgot'), {email});
  }

  reset(email: string, token: string, password: string): Observable<undefined> {
    return of(null)
      .pipe(
        switchMap(() => this.sodium.deriveKey(password)),
        switchMap(async derivedKey => {
          memzero(derivedKey.key.privateKey);

          return {
            public_key: await this.sodium.toHex(derivedKey.key.publicKey),
            salt: await this.sodium.toHex(derivedKey.salt),
            ops_limit: derivedKey.opsLimit,
            mem_limit: derivedKey.memLimit,
            algorithm: derivedKey.algorithm,
          }
        }),
        switchMap(keyData => this.http.post<undefined>(buildUrl('auth', 'reset'), {
          ...keyData,
          email,
          token,
        }))
      );
  }

  refreshRole(): void {
    this.reloadRole$.next();
  }

  private getToken(): Observable<string> {
    if (this._token) {
      return of(this._token);
    }

    const token = crypto.getRandomValues(new Uint8Array(64));

    return from(this.sodium.toHex(token))
      .pipe(
        tap(hexToken => this._token = hexToken),
        finalize(() => {
          memzero(token)
        })
      );
  }
}
