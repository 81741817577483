export const supported = (): boolean => {
  const global: any = typeof window !== 'undefined'
    ? window
    : (typeof self !== 'undefined'
        ? self
        : (typeof globalThis !== 'undefined'
            ? globalThis
            : null
        )
    );
  if (!global) {
    return true;
  }

  const crypto: Crypto = global.crypto || global.msCrypto || global.webkitCrypto || global.mozCrypto;

  // tslint:disable-next-line:no-unbound-method
  if (!crypto || typeof crypto.getRandomValues !== 'function' || !global.Uint8Array || !global.requestAnimationFrame) {
    console.error(new Error('current browser is unsupported'));

    return false;
  }

  if (!global.crypto) {
    global.crypto = crypto;
  }

  return true;
};
