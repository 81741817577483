import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import {Injectable, Injector} from '@angular/core';
import {MatDialog} from "@angular/material";
import {Observable, of, throwError} from "rxjs";
import {catchError, finalize, map, publishReplay, refCount, retryWhen, switchMap} from "rxjs/operators";

import {environment} from "../../../environments/environment";
import {LoginDialogComponent} from "../../modals/login-dialog/login-dialog.component";
import {buildUrl} from "../../utils/build-url";
import {TokenResponse} from "../auth/auth.types";
import {TokenStoreService} from "../token/token-store.service";

const excludeRegExp = new RegExp(`^(?:${buildUrl('auth')}|${buildUrl('session')})`);

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private refreshing: Observable<boolean>;

  constructor(private readonly injector: Injector,
              private readonly dialog: MatDialog,
              private readonly tokenStore: TokenStoreService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('X-Skip-Auth')) {
      return next.handle(req.clone({
        headers: req.headers.delete('X-Skip-Auth')
      }));
    }

    if (
      req.headers.has('Authorization') ||
      req.url.indexOf(environment.apiUrl) !== 0 ||
      excludeRegExp.test(req.url)
    ) {
      return next.handle(req);
    }

    return this.addToken(req)
      .pipe(
        switchMap(authorizedRequest => next.handle(authorizedRequest)),
        retryWhen(errors => errors.pipe(
          switchMap((error, count) => this.refreshToken(error, count)),
        ))
      );
  }

  private getToken(): Observable<TokenResponse> {
    if (this.refreshing) {
      return this.refreshing.pipe(switchMap(() => this.tokenStore.token));
    }

    return this.tokenStore.token;
  }

  private addToken(req): Observable<HttpRequest<any>> {
    return this.getToken()
      .pipe(
        map(
          token => token
            ? req.clone({headers: req.headers.set('Authorization', `${token.type} ${token.token}`)})
            : req
        )
      );
  }

  private refreshToken(error: any, count: number): Observable<boolean> {
    if (count > 0 || !(error instanceof HttpErrorResponse) || error.status !== 401) {
      return throwError(error);
    }

    if (!this.refreshing) {
      this.refreshing = this.tokenStore.token
        .pipe(
          switchMap(
            token => this.tokenStore.fromStorage()
              .pipe(
                switchMap(stored => {
                  if (stored && (!token || token.token !== stored.token)) {
                    return of(true);
                  }

                  if (!token) {
                    return throwError(new Error('unauthenticated'));
                  }

                  const http = this.injector.get(HttpClient);

                  return http.post<TokenResponse>(buildUrl('auth', 'refresh'), null, {
                    headers: {
                      Authorization: `${token.type} ${token.token}`
                    }
                  })
                    .pipe(
                      switchMap(newToken => this.tokenStore.setToken(newToken)),
                      catchError(reason => {
                        console.warn(reason);

                        return of(false);
                      })
                    );
                })
              )
          ),
          switchMap(refreshed => {
            if (refreshed) {
              return of(true);
            }

            const ref = this.dialog.open(LoginDialogComponent, {
              panelClass: ['no-padding', 'dialog-sm'],
              closeOnNavigation: true,
              disableClose: true,
            });

            return ref.afterClosed()
              .pipe(map(result => !!result));
          }),
          // switchMap(refreshed => {
          //   if (refreshed) {
          //     return of(true);
          //   }
          //
          //   return this.tokenStore.setToken(null)
          //     .pipe(map(() => false));
          // }),
          finalize(() => this.refreshing = null),
          publishReplay(1),
          refCount()
        );
    }

    return this.refreshing.pipe(
      catchError(() => throwError(error)),
      switchMap(refreshed => {
        if (refreshed) {
          return of(true);
        }

        this.tokenStore.setToken(null);

        return throwError(error);
      })
    );
  }
}
