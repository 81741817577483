import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, ThemePalette} from "@angular/material";

export interface ConfirmDialogData {
  title?: string;
  titleData?: { [key: string]: any },
  body?: string;
  bodyData?: { [key: string]: any },
  cancelButton?: string;
  okButton?: string;
  color?: ThemePalette;
}

@Component({
  selector: 'evicare-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  title: string;
  titleData: { [p: string]: any };
  body: string;
  bodyData: { [p: string]: any };
  cancelButton: string;
  okButton: string;
  color: ThemePalette;

  constructor(private readonly dialog: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: ConfirmDialogData) {
    this.title = data.title;
    this.titleData = data.titleData;
    this.body = data.body;
    this.bodyData = data.bodyData;
    this.cancelButton = data.cancelButton;
    this.okButton = data.okButton;
    this.color = data.color || 'primary';
  }

  submit(event: Event): void {
    event.preventDefault();

    this.dialog.close(true);
  }
}
