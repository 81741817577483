import * as sodium from 'libsodium-wrappers-sumo';

import {HashAlgorithm, SodiumService} from "./sodium.types";

export class SodiumServiceBase implements SodiumService {
  protected sodium: typeof sodium;

  async crypto_aead_xchacha20poly1305_ietf_KEYBYTES(): Promise<number> {
    await this.ready();

    return this.sodium.crypto_aead_xchacha20poly1305_ietf_KEYBYTES;
  }

  async crypto_pwhash_SALTBYTES(): Promise<number> {
    await this.ready();

    return this.sodium.crypto_pwhash_SALTBYTES;
  }

  async crypto_pwhash_MEMLIMIT_INTERACTIVE(): Promise<number> {
    await this.ready();

    return this.sodium.crypto_pwhash_MEMLIMIT_INTERACTIVE
  }

  async crypto_pwhash_MEMLIMIT_MIN(): Promise<number> {
    await this.ready();

    return this.sodium.crypto_pwhash_MEMLIMIT_MIN
  }

  async crypto_pwhash_MEMLIMIT_MAX(): Promise<number> {
    await this.ready();

    return this.sodium.crypto_pwhash_MEMLIMIT_MAX;
  }

  async crypto_pwhash_MEMLIMIT_SENSITIVE(): Promise<number> {
    await this.ready();

    return this.sodium.crypto_pwhash_MEMLIMIT_SENSITIVE;
  }

  async crypto_pwhash_OPSLIMIT_INTERACTIVE(): Promise<number> {
    await this.ready();

    return this.sodium.crypto_pwhash_OPSLIMIT_INTERACTIVE;
  }

  async crypto_pwhash_OPSLIMIT_MIN(): Promise<number> {
    await this.ready();

    return this.sodium.crypto_pwhash_OPSLIMIT_MIN;
  }

  async crypto_pwhash_OPSLIMIT_MAX(): Promise<number> {
    await this.ready();

    return this.sodium.crypto_pwhash_OPSLIMIT_MAX;
  }

  async crypto_pwhash_OPSLIMIT_SENSITIVE(): Promise<number> {
    await this.ready();

    return this.sodium.crypto_pwhash_OPSLIMIT_SENSITIVE;
  }

  async crypto_pwhash_ALG_DEFAULT(): Promise<number> {
    await this.ready();

    return this.sodium.crypto_pwhash_ALG_DEFAULT;
  }

  async crypto_pwhash_ALG_ARGON2I13(): Promise<number> {
    await this.ready();

    return this.sodium.crypto_pwhash_ALG_ARGON2I13;
  }

  async crypto_pwhash_ALG_ARGON2ID13(): Promise<number> {
    await this.ready();

    return this.sodium.crypto_pwhash_ALG_ARGON2ID13;
  }

  /**
   * Wait for sodium to be ready
   */
  async ready(): Promise<boolean> {
    if (!this.sodium) {
      this.sodium = sodium;
    }

    await this.sodium.ready;

    return true;
  }

  /**
   * Derive an asymmetric key pair from a password and salt
   */
  async deriveKey(password: string | Uint8Array,
                  salt?: string | Uint8Array,
                  opsLimit?: number,
                  memLimit?: number,
                  algorithm?: HashAlgorithm): Promise<{ key: sodium.KeyPair, salt: Uint8Array, opsLimit: number, memLimit: number, algorithm: HashAlgorithm }> {
    let seed: Uint8Array;

    try {
      await this.ready();

      const _salt = salt ?
        (typeof salt === 'string' ? await this.fromHex(salt) : salt) :
        crypto.getRandomValues(new Uint8Array(this.sodium.crypto_pwhash_SALTBYTES));

      const _opsLimit = opsLimit || this.sodium.crypto_pwhash_OPSLIMIT_INTERACTIVE;
      const _memLimit = memLimit || this.sodium.crypto_pwhash_MEMLIMIT_INTERACTIVE;
      const _algorithm: HashAlgorithm = algorithm || this.sodium.crypto_pwhash_ALG_DEFAULT;

      seed = this.sodium.crypto_pwhash(
        this.sodium.crypto_sign_SEEDBYTES,
        password,
        _salt,
        _opsLimit,
        _memLimit,
        _algorithm,
        'uint8array'
      );

      return {
        key: this.sodium.crypto_sign_seed_keypair(seed, 'uint8array'),
        salt: _salt,
        opsLimit: _opsLimit,
        memLimit: _memLimit,
        algorithm: _algorithm
      };
    } finally {
      if (seed) {
        this.sodium.memzero(seed);
      }
    }
  }

  /**
   * Helper to quickly encrypt a message using a password and salt
   */
  async passwordEncrypt(message: string | Uint8Array,
                        password: string,
                        salt: string | Uint8Array,
                        opsLimit?: number,
                        memLimit?: number,
                        algorithm?: HashAlgorithm): Promise<Uint8Array> {
    let keyPair: sodium.KeyPair;

    try {
      keyPair = (await this.deriveKey(password, salt, opsLimit, memLimit, algorithm)).key;

      return await this.asymmetricEncrypt(message, this.sodium.crypto_sign_ed25519_pk_to_curve25519(keyPair.publicKey));
    } finally {
      if (keyPair) {
        this.sodium.memzero(keyPair.privateKey);
      }
    }
  }

  /**
   * Helper to quickly decrypt a message using a password and salt
   */
  async passwordDecrypt(message: string | Uint8Array,
                        password: string,
                        salt: string | Uint8Array,
                        opsLimit?: number,
                        memLimit?: number,
                        algorithm?: HashAlgorithm): Promise<Uint8Array> {
    let keyPair: sodium.KeyPair;
    let privateKey: Uint8Array;

    try {
      keyPair = (await this.deriveKey(password, salt, opsLimit, memLimit, algorithm)).key;

      const publicKey = this.sodium.crypto_sign_ed25519_pk_to_curve25519(keyPair.publicKey);
      privateKey = this.sodium.crypto_sign_ed25519_sk_to_curve25519(keyPair.privateKey);

      return await this.asymmetricDecrypt(message, publicKey, privateKey);
    } finally {
      if (keyPair) {
        this.sodium.memzero(keyPair.privateKey);
      }

      if (privateKey) {
        this.sodium.memzero(privateKey);
      }
    }
  }

  /**
   * Helper to quickly sign a message using a password and salt
   */
  async passwordSign(message: string | Uint8Array,
                     password: string,
                     salt: string | Uint8Array,
                     opsLimit?: number,
                     memLimit?: number,
                     algorithm?: HashAlgorithm): Promise<Uint8Array> {
    let keyPair: sodium.KeyPair;

    try {
      keyPair = (await this.deriveKey(password, salt, opsLimit, memLimit, algorithm)).key;

      return await this.sign(message, keyPair.privateKey);
    } finally {
      if (keyPair) {
        this.sodium.memzero(keyPair.privateKey);
      }
    }
  }

  /**
   * Helper to quickly verify a message using a password and salt
   */
  async passwordVerify(message: string | Uint8Array,
                       password: string,
                       salt: string | Uint8Array,
                       opsLimit?: number,
                       memLimit?: number,
                       algorithm?: HashAlgorithm): Promise<Uint8Array> {
    let keyPair: sodium.KeyPair;

    try {
      keyPair = (await this.deriveKey(password, salt, opsLimit, memLimit, algorithm)).key;

      return await this.verify(message, keyPair.publicKey);
    } finally {
      if (keyPair) {
        this.sodium.memzero(keyPair.privateKey);
      }
    }
  }

  /**
   * Helper to quickly create a signature for a message using a password and salt
   */
  async passwordSignature(message: string | Uint8Array,
                          password: string, salt: string | Uint8Array,
                          opsLimit?: number,
                          memLimit?: number,
                          algorithm?: HashAlgorithm): Promise<Uint8Array> {
    let keyPair: sodium.KeyPair;

    try {
      keyPair = (await this.deriveKey(password, salt, opsLimit, memLimit, algorithm)).key;

      return await this.signature(message, keyPair.privateKey);
    } finally {
      if (keyPair) {
        this.sodium.memzero(keyPair.privateKey);
      }
    }
  }

  /**
   * Helper to quickly verify a message signature using a password and salt
   */
  async passwordVerifySignature(signature: string | Uint8Array,
                                message: string | Uint8Array,
                                password: string,
                                salt: string | Uint8Array,
                                opsLimit?: number,
                                memLimit?: number,
                                algorithm?: HashAlgorithm): Promise<boolean> {
    let keyPair: sodium.KeyPair;

    try {
      keyPair = (await this.deriveKey(password, salt, opsLimit, memLimit, algorithm)).key;

      return await this.verifySignature(signature, message, keyPair.publicKey);
    } finally {
      if (keyPair) {
        this.sodium.memzero(keyPair.privateKey);
      }
    }
  }

  /**
   * Encrypt a message using public key cryptography
   */
  async asymmetricEncrypt(message: string | Uint8Array,
                          publicKey: string | Uint8Array): Promise<Uint8Array> {
    await this.ready();

    const _publicKey = typeof publicKey === 'string' ? await this.fromHex(publicKey) : publicKey;

    return this.sodium.crypto_box_seal(message, _publicKey, 'uint8array');
  }

  /**
   * Decrypt a message using public key cryptography
   */
  async asymmetricDecrypt(message: string | Uint8Array,
                          publicKey: string | Uint8Array,
                          privateKey: string | Uint8Array): Promise<Uint8Array> {
    let _privateKey: Uint8Array;

    try {
      await this.ready();

      const _publicKey = typeof publicKey === 'string' ? await this.fromHex(publicKey) : publicKey;

      if (typeof privateKey === 'string') {
        _privateKey = await this.fromHex(privateKey);
      }

      return this.sodium.crypto_box_seal_open(message, _publicKey, _privateKey || (privateKey as Uint8Array), 'uint8array');
    } finally {
      if (_privateKey) {
        this.sodium.memzero(_privateKey);
      }
    }
  }

  /**
   * Sign a message using an asymmetric private key
   */
  async sign(message: string | Uint8Array,
             privateKey: string | Uint8Array): Promise<Uint8Array> {
    let _privateKey: Uint8Array;

    try {
      await this.ready();


      if (typeof privateKey === 'string') {
        _privateKey = await this.fromHex(privateKey);
      }

      return this.sodium.crypto_sign(message, _privateKey || (privateKey as Uint8Array), 'uint8array');
    } finally {
      if (_privateKey) {
        this.sodium.memzero(_privateKey);
      }
    }
  }

  /**
   * Verify the message using an asymmetric public key
   */
  async verify(message: string | Uint8Array,
               publicKey: string | Uint8Array): Promise<Uint8Array> {
    await this.ready();

    const _publicKey = typeof publicKey === 'string' ? await this.fromHex(publicKey) : publicKey;

    return this.sodium.crypto_sign_open(message, _publicKey, 'uint8array');
  }

  /**
   * Generate a signature for the provided message using an asymmetric private key
   */
  async signature(message: string | Uint8Array,
                  privateKey: string | Uint8Array): Promise<Uint8Array> {
    let _privateKey: Uint8Array;

    try {
      await this.ready();

      if (typeof privateKey === 'string') {
        _privateKey = await this.fromHex(privateKey);
      }

      return this.sodium.crypto_sign_detached(message, _privateKey || (privateKey as Uint8Array), 'uint8array');
    } finally {
      if (_privateKey) {
        this.sodium.memzero(_privateKey);
      }
    }
  }

  /**
   * Verify a signature for the provided the message using an asymmetric public key
   */
  async verifySignature(signature: string | Uint8Array,
                        message: string | Uint8Array,
                        publicKey: string | Uint8Array): Promise<boolean> {
    await this.ready();

    const _publicKey = typeof publicKey === 'string' ? await this.fromHex(publicKey) : publicKey;
    const _signature = typeof signature === 'string' ? await this.fromHex(signature) : signature;

    return this.sodium.crypto_sign_verify_detached(_signature, message, _publicKey);
  }

  /**
   * Generate a key exchange key pair
   */
  async generateKeyPair(): Promise<sodium.KeyPair> {
    await this.ready();

    return this.sodium.crypto_kx_keypair('uint8array');
  }

  /**
   * Derive the session keys from the client key pair and server public key
   */
  async sessionKeys(keyPair: sodium.KeyPair,
                    publicKey: string | Uint8Array): Promise<sodium.CryptoKX> {
    await this.ready();

    const _publicKey = typeof publicKey === 'string' ? await this.fromHex(publicKey) : publicKey;

    return this.sodium.crypto_kx_client_session_keys(keyPair.publicKey, keyPair.privateKey, _publicKey);
  }

  /**
   * Encrypt a message using symmetric encryption with (optional) additional data
   */
  async aeadEncrypt(message: string | Uint8Array,
                    key: string | Uint8Array,
                    additionalData?: string | Uint8Array): Promise<Uint8Array> {
    let _key: Uint8Array;
    let nonce: Uint8Array;
    let encrypted: Uint8Array;

    try {
      await this.ready();

      if (typeof key === 'string') {
        _key = await this.fromHex(key);
      }

      nonce = crypto.getRandomValues(new Uint8Array(this.sodium.crypto_aead_xchacha20poly1305_ietf_NPUBBYTES));

      encrypted = this.sodium.crypto_aead_xchacha20poly1305_ietf_encrypt(
        message,
        additionalData,
        null,
        nonce,
        _key || (key as Uint8Array),
        'uint8array'
      );

      const result = new Uint8Array(nonce.byteLength + encrypted.byteLength);
      result.set(nonce, 0);
      result.set(encrypted, nonce.byteLength);

      return result;
    } finally {
      if (_key) {
        this.sodium.memzero(_key);
      }

      if (nonce) {
        this.sodium.memzero(nonce);
      }

      if (encrypted) {
        this.sodium.memzero(encrypted);
      }
    }
  }

  /**
   * Decrypt a message using symmetric encryption with (optional) additional data
   */
  async aeadDecrypt(message: string | Uint8Array,
                    key: string | Uint8Array,
                    additionalData?: string | Uint8Array): Promise<Uint8Array> {
    let _key: Uint8Array;
    let _message: Uint8Array;
    let nonce: Uint8Array;
    let payload: Uint8Array;

    try {
      await this.ready();

      if (typeof key === 'string') {
        _key = await this.fromHex(key);
      }

      if (typeof message === 'string') {
        _message = await this.fromHex(message);
      }

      nonce = (_message || (message as Uint8Array)).slice(0, this.sodium.crypto_aead_xchacha20poly1305_ietf_NPUBBYTES);
      payload = (_message || (message as Uint8Array)).slice(this.sodium.crypto_aead_xchacha20poly1305_ietf_NPUBBYTES);

      return this.sodium.crypto_aead_xchacha20poly1305_ietf_decrypt(
        null,
        payload,
        additionalData,
        nonce,
        _key || (key as Uint8Array),
        'uint8array'
      );
    } finally {
      if (_key) {
        this.sodium.memzero(_key);
      }

      if (_message) {
        this.sodium.memzero(_message);
      }

      if (nonce) {
        this.sodium.memzero(nonce);
      }

      if (payload) {
        this.sodium.memzero(payload);
      }
    }
  }

  /**
   * Convert a hex string to Uint8Array
   */
  async fromHex(value: string): Promise<Uint8Array> {
    await this.ready();

    return this.sodium.from_hex(value);
  }

  /**
   * Convert a Uint8Array to a hex string
   */
  async toHex(value: Uint8Array): Promise<string> {
    await this.ready();

    return this.sodium.to_hex(value);
  }

  /**
   * Convert a string into a Uint8Array
   */
  async fromString(value: string): Promise<Uint8Array> {
    await this.ready();

    return this.sodium.from_string(value);
  }

  /**
   * Convert a Uint8Array into a string
   */
  async toString(value: Uint8Array): Promise<string> {
    await this.ready();

    return this.sodium.to_string(value);
  }
}
