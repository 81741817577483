import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";

import {environment} from "../../../environments/environment";
import {TokenStoreService} from "../token/token-store.service";

@Injectable()
export class ScopeInterceptor implements HttpInterceptor {
  constructor(private readonly tokenStore: TokenStoreService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('X-Skip-Auth')) {
      return next.handle(req.clone({
        headers: req.headers.delete('X-Skip-Auth')
      }));
    }

    if (req.url.indexOf(environment.apiUrl) !== 0) {
      return next.handle(req);
    }

    return this.tokenStore.role.pipe(
      switchMap(role => {
        if (!role || role.role === 'system') {
          return next.handle(req);
        }

        return next.handle(req.clone({
          headers: req.headers.append(role.role === 'association' ? 'X-Association' : 'X-Specialist', role.roleId)
        }));
      }),
      catchError(reason => {
        if (
          reason instanceof HttpErrorResponse &&
          reason.status === 422 &&
          typeof reason.error === 'object' &&
          'errors' in reason.error &&
          typeof reason.error.errors === 'object' &&
          'scope_invalid' in reason.error.errors
        ) {
          this.tokenStore.setRole(null)
            .subscribe({
              error: error => {
                console.warn(error);
              }
            });
        }

        return throwError(reason);
      })
    );
  }
}
