import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';

@Directive({
  selector: 'img[evicareBlobSrc]'
})
export class BlobSrcDirective implements OnChanges, OnDestroy, OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('evicareBlobSrc') readonly blob: Blob | string;

  private blobUrl: string;

  constructor(private readonly element: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.evicareBlobSrc) {
      this.updateSrc();
    }
  }

  ngOnDestroy(): void {
    if (this.blobUrl) {
      URL.revokeObjectURL(this.blobUrl);
    }
  }

  ngOnInit(): void {
    this.updateSrc();
  }

  private updateSrc(): void {
    if (this.blobUrl) {
      URL.revokeObjectURL(this.blobUrl);
    }

    if (typeof this.blob === 'string') {
      (this.element.nativeElement as HTMLImageElement).src = this.blob;

      return;
    }

    if (this.blob instanceof Blob) {
      this.blobUrl = URL.createObjectURL(this.blob);

      (this.element.nativeElement as HTMLImageElement).src = this.blobUrl;

      return;
    }

    throw new Error('Unsupported input');
  }
}
