import {Injectable, OnDestroy} from '@angular/core';
import {JSONSchemaObject, LocalStorage} from '@ngx-pwa/local-storage';
import {Observable, of, ReplaySubject} from "rxjs";
import {catchError, distinctUntilChanged, first, map, tap} from "rxjs/operators";

import {UserRole} from "../../auth/auth.types";
import {TokenResponse} from "../auth/auth.types";

@Injectable({
  providedIn: 'root'
})
export class TokenStoreService implements OnDestroy {
  private readonly _role = new ReplaySubject<{ role: UserRole, roleId?: string }>(1);
  private readonly _token = new ReplaySubject<TokenResponse>(1);

  /**
   * Get the current authentication state
   */
  get authenticated(): Observable<boolean> {
    return this.token.pipe(map(token => !!token));
  }

  /**
   * Listen for authentication changes
   */
  get authenticatedChange(): Observable<boolean> {
    return this.tokenChange.pipe(map(token => !!token));
  }

  /**
   * Get the current token (or null if none is available)
   */
  get token(): Observable<TokenResponse | null> {
    return this.tokenChange.pipe(first());
  }

  /**
   * Listen for token changes
   */
  get tokenChange(): Observable<TokenResponse | null> {
    return this._token.pipe(distinctUntilChanged((lhs, rhs) => lhs && rhs ? lhs.token === rhs.token : lhs === rhs));
  }

  get role(): Observable<{ role: UserRole, roleId?: string }> {
    return this.roleChange.pipe(first());
  }

  get roleChange(): Observable<{ role: UserRole, roleId?: string }> {
    return this._role.pipe(
      distinctUntilChanged(
        (lhs, rhs) => lhs && rhs
          ? lhs.role === rhs.role && lhs.roleId === rhs.roleId
          : lhs === rhs
      )
    );
  }

  constructor(private readonly localStorage: LocalStorage) {
    this.init();
  }

  ngOnDestroy(): void {
    this._role.complete();
    this._token.complete();
  }

  /**
   * Save the token in storage and update the internal state
   */
  setToken(token: TokenResponse | null): Observable<boolean> {
    if (!token) {
      return this.localStorage.removeItem('token')
        .pipe(
          tap(() => {
            this._token.next(null);
          })
        );
    }

    return this.localStorage.setItem('token', token)
      .pipe(
        tap(() => {
          this._token.next(token);
        })
      );
  }

  setRole(role: { role: UserRole, roleId?: string }): Observable<boolean> {
    if (!role) {
      return this.localStorage.removeItem('role')
        .pipe(
          tap(() => {
            this._role.next(null);
          })
        );
    }

    return this.localStorage.setItem('role', role)
      .pipe(
        tap(() => {
          this._role.next(role);
        })
      );
  }

  fromStorage(): Observable<TokenResponse | null> {
    const schema: JSONSchemaObject = {
      type: 'object',
      properties: {
        token: {
          type: 'string',
        },
        type: {
          type: 'string',
        },
        expires: {
          type: 'number',
          minimum: 0,
        },
      },
      required: ['token', 'type', 'expires'],
    };

    return this.localStorage.getItem<TokenResponse>('token', {schema})
      .pipe(
        catchError(() => of<TokenResponse>(null)),
        tap(token => {
          this._token.next(token);
        })
      );
  }

  /**
   * Fetch the token from storage (if any)
   */
  private init(): void {
    let schema: JSONSchemaObject = {
      type: 'object',
      properties: {
        token: {
          type: 'string',
        },
        type: {
          type: 'string',
        },
        expires: {
          type: 'number',
          minimum: 0,
        },
      },
      required: ['token', 'type', 'expires'],
    };

    this.localStorage.getItem<TokenResponse>('token', {schema})
      .pipe(catchError(() => of<TokenResponse>(null)))
      .subscribe(token => {
        this._token.next(token);
      });

    schema = {
      type: 'object',
      properties: {
        role: {
          type: 'string',
          enum: ['association', 'specialist', 'system']
        },
        roleId: {
          type: 'string',
        },
      },
      required: ['role'],
    };

    this.localStorage.getItem<{ role: UserRole, roleId?: string }>('role', {schema})
      .pipe(catchError(() => of<{ role: UserRole, roleId: string }>(null)))
      .subscribe(role => {
        this._role.next(role);
      });
  }
}
