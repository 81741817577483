import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BaseModule} from "../../base/base.module";
import {DirectivesModule} from "../../directives/directives.module";
import {TwoFactorShowModule} from "../two-factor-show/two-factor-show.module";
import {TwoFactorModule} from "../two-factor/two-factor.module";
import {VerifyEmailModule} from "../verify-email/verify-email.module";

import {LoginDialogComponent} from "./login-dialog.component";

@NgModule({
  declarations: [LoginDialogComponent],
  entryComponents: [LoginDialogComponent],
  imports: [
    CommonModule,
    BaseModule,
    DirectivesModule,
    TwoFactorModule,
    TwoFactorShowModule,
    VerifyEmailModule,
  ]
})
export class LoginDialogModule {
}
