import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FileReaderService {
  readAsArrayBuffer(value: Blob): Observable<ArrayBuffer> {
    return new Observable<ArrayBuffer>(subscriber => {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        subscriber.next(reader.result as ArrayBuffer);
        subscriber.complete();
      });

      reader.addEventListener('error', () => {
        subscriber.error(reader.error);
      });

      reader.addEventListener('abort', () => {
        subscriber.error(new Error('aborted'));
      });

      reader.readAsArrayBuffer(value);

      return () => {
        reader.abort();
      };
    });
  }

  readAsDataURL(value: Blob): Observable<string> {
    return new Observable<string>(subscriber => {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        subscriber.next(reader.result as string);
        subscriber.complete();
      });

      reader.addEventListener('error', () => {
        subscriber.error(reader.error);
      });

      reader.addEventListener('abort', () => {
        subscriber.error(new Error('aborted'));
      });

      reader.readAsDataURL(value);

      return () => {
        reader.abort();
      };
    });
  }
}
